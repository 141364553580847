import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { DELETE_TOKEN } from "../../store/auth/types";
import { clearInquiries } from "../../store/inquiries/actions";
import useTokenState from "./useTokenState";
import useTokenUser from "./useTokenUserInfo";

export default function useLogout() {
  const token = useTokenState();
  const history = useHistory();
  const user = useTokenUser();
  const dispatch = useDispatch();

  const logout = async () => {
    const logoutUrl =
      process.env.REACT_APP_LOGOUT_URL + `?t=${token}&app_id=kirnu`;

    dispatch(clearInquiries());
    dispatch({ type: DELETE_TOKEN });

    // Redirect to SAML logout if not in development or using non-Suomifi token.
    if ((user && user.upn) || process.env.NODE_ENV === "development") {
      history.push("/");
    } else window.location.href = logoutUrl;
  };

  return logout;
}
