{
  "kirjaudu.title": "Tervetuloa Luonnonvarakeskuksen Kirnu-sovellukseen!",
  "kirjaudu.ohjeteksti1": "Tervetuloa vastaamaan Luken sähköiseen tiedonkeruuseen. Antamasi tiedot käsitellään luottamuksellisina ja niitä käytetään ainoastaan tilastotarkoituksiin.",
  "kirjaudu.ohjeteksti2.1": "Lisätietoja vastaamiseen liittyen saat sähköpostitse osoitteesta ",
  "kirjaudu.ohjeteksti2.2": "Yhteyshenkilöiden puhelinnumerot löytyvät Luken ",
  "kirjaudu.ohjeteksti2.linkki": "https://stat.luke.fi/sites/default/files/tayttoohje_tilastokysely_maidon_ja_maitotuotteiden_tuotannosta_ja_tuottajahinnoista.pdf",
  "kirjaudu.ohjeteksti2.linkkiTeksti": "täyttöohjeesta",
  "kirjaudu.ohjeteksti3.1": "Ohjeita tunnistautumiseen ja valtuuttamiseen löydät Luken ",
  "kirjaudu.ohjeteksti3.linkkiTeksti": "verkkosivuilta.",
  "kirjaudu.suomifi": "Tunnistaudu",
  "kirjaudu.tunnistautuminenEpaonnistui.title": "Tunnistautuminen sovellukseen keskeytyi.",
  "kirjaudu.tunnistautuminenEpaonnistui.return": "Palaa etusivulle",
  "common.logout": "Kirjaudu ulos",
  "common.lähetä": "Lähetä",
  "common.hello": "Hei",
  "common.fi": "Suomeksi",
  "common.se": "Ruotsiksi",
  "common.kpl": "kpl",
  "common.snt": "snt",
  "common.sntperlitra": "snt/l",
  "common.edellinen": "Edellinen",
  "common.seuraava": "Seuraava",
  "common.lisatiedot.ohjeteksti": "Voit ilmoittaa lisätietoja tämän ajankohdan tietoja koskien, esim. selityksen poikkeavasta määrä- tai hintatiedosta. Tämä auttaa Tilastopalvelua tietojen tarkastuksessa. Myös muuttuneet yhteystiedot voit ilmoittaa tässä. Jos aiempien kuukausien ilmoittamasi tiedot ovat muuttuneet, lähetä sähköpostia osoitteeseen",
  "common.lisatiedot.ohjeteksti.lyhyt": "Voit ilmoittaa lisätietoja tämän ajankohdan tietoja koskien, esim. selityksen poikkeavasta määrä- tai hintatiedosta. Tämä auttaa Tilastopalvelua tietojen tarkastuksessa. Myös muuttuneet yhteystiedot voit ilmoittaa tässä.",
  "common.valitse.lomake": "Aloita valitsemalla lomake.",
  "common.valitse.vuosi": "Valitse vuosi, jonka tietoja haluat muokata.",
  "common.valitse.kuukausi": "Valitse kuukausi, jonka tietoja haluat muokata.",
  "common.field.lisätiedot": "Lisätiedot",
  "common.title.lisätiedot": "Lisätiedot ja vahvistus",
  "common.edellinenkuukausi": "Edellisen kuukauden tieto",
  "common.edellinenvuosi": "Edellisen vuoden tieto",
  "common.tammikuu": "tammikuu",
  "common.helmikuu": "helmikuu",
  "common.maaliskuu": "maaliskuu",
  "common.huhtikuu": "huhtikuu",
  "common.toukokuu": "toukokuu",
  "common.kesäkuu": "kesäkuu",
  "common.heinäkuu": "heinäkuu",
  "common.elokuu": "elokuu",
  "common.syyskuu": "syyskuu",
  "common.lokakuu": "lokakuu",
  "common.marraskuu": "marraskuu",
  "common.joulukuu": "joulukuu",
  "common.vuosi": "vuosi",
  "common.lomake.ohjelinkki.teksti": "Täyttöohje",
  "common.lomake.ohjelinkki": "linkki",
  "tuotantotiedot.title": "Maidontuotanto, tuottajahinta ja maitotuotteiden valmistus",
  "tuotantotiedot.description": "Tallenna yrityksesi kuukausittaiset tiedot.",
  "tuotantotiedot.vastaanotto.title": "Maidon vastaanotto ja tuottajahinta",
  "tuotantotiedot.PoikkeavaArvo": "Suuri muutos (yli 20%) verrattuna edelliseen arvoon.",
  "tuotantotiedot.luomumaidontuottajahinta.title": "Luomumaidon tuottajahinta ja arvio tuottajahinnasta",
  "tuotantotiedot.luomumaidontuottajahinta.arvioKuluvanKuuKaudenHinnasta": "Arvio kuluvan kuukauden keskimääräisestä tuottajahinnasta",
  "tuotantotiedot.luomumaidontuottajahinta.field.LuomumaidonHinta": "Luomumaidon hinta",
  "tuotantotiedot.luomumaidontuottajahinta.field.LuomumaidostaMaksettuYhteensa": "Luomumaidosta maksettu rahasumma",
  "tuotantotiedot.luomumaidontuottajahinta.field.LuomuMaidonArvioituHinta": "Hinta-arvio kaikelle maidolle",
  "tuotantotiedot.tuoretuotteidenvalmistus.title": "Tuoretuotteiden valmistus",
  "tuotantotiedot.tuoretuotteidenvalmistus.ohjeteksti": "Maitotuotteiden valmistusmääriin ilmoitetaan yrityksen omassa tuotantolaitoksessa valmistettujen maitotuotteiden valmistusmäärät ja rahtivalmistus. Valmistuslukuihin ilmoitetaan vain markkinoitavien lopputuotteiden valmistus. Luomutuotteet lasketaan mukaan tuoteryhmiin.",
  "tuotantotiedot.tuoretuotteidenvalmistus.ohjeteksti2": "Maito- ja herapohjaisissa juomissa ilmoitetaan tuotteet, jotka sisältävät vähintään 50 prosenttia maitotuotteita.",
  "tuotantotiedot.tuoretuotteidenvalmistus.ohjeteksti3": "Muut tuoretuotteet ryhmässä ilmoitetaan mm. jäätelö, erilaiset maitopohjaiset kastikkeet ja jälkiruoat.",
  "tuotantotiedot.tuoretuotteidenvalmistus.maito": "Maidot",
  "tuotantotiedot.tuoretuotteidenvalmistus.kermat": "Kermat",
  "tuotantotiedot.tuoretuotteidenvalmistus.viilit": "Viilit",
  "tuotantotiedot.tuoretuotteidenvalmistus.jogurtit": "Jogurtit",
  "tuotantotiedot.tuoretuotteidenvalmistus.rahka": "Rahkat",
  "tuotantotiedot.tuoretuotteidenvalmistus.vanukkaat": "Vanukkaat",
  "tuotantotiedot.tuoretuotteidenvalmistus.muuttuoretuotteet": "Muut tuoretuotteet",
  "tuotantotiedot.tuoretuotteidenvalmistus.maitojaherapohjaisetjuomat": "Maito- ja herapohjaiset juomat",
  "tuotantotiedot.tuoretuotteidenvalmistus.hapatetutkermavalmisteet": "Hapatetut kermavalmisteet",
  "tuotantotiedot.juustojamuut.title": "Muiden maitotuotteiden valmistus, kotimaan myynti ja varastot",
  "tuotantotiedot.juustojamuut.ohjeteksti": "Maitotuotteiden valmistusmääriin ilmoitetaan yrityksen omassa tuotantolaitoksessa valmistettujen maitotuotteiden valmistusmäärät ja rahtivalmistus. Valmistuslukuihin ilmoitetaan vain markkinoitavien lopputuotteiden valmistus. Luomutuotteet lasketaan mukaan tuoteryhmiin.",
  "tuotantotiedot.vastaanotto.ohjeteksti1.1": "Ilmoita tuottajilta vastaanotetun ja tilitetyn lehmänmaidon tiedot. Ilmoita hinnat, pitoisuudet, rasva- ja valkuaiskymmenykset kahden desimaalin tarkkuudella, muut tiedot kokonaislukuina.",
  "tuotantotiedot.vastaanotto.field.MaidontuottajienLukumaara": "Maidontuottajien lukumäärä",
  "tuotantotiedot.vastaanotto.field.LuomutuottajienLukumaara": "...josta luomutuottajia",
  "tuotantotiedot.vastaanotto.field.MaidonVastaanottoYhteensa": "Maidon vastaanotto",
  "tuotantotiedot.vastaanotto.field.LuomumaidonVastaanottoYhteensa": "...josta luomumaitoa",
  "tuotantotiedot.vastaanotto.field.MaidonRasvapitoisuus": "Maidon rasvapitoisuus",
  "tuotantotiedot.vastaanotto.field.MaidonValkuaispitoisuus": "Maidon valkuaispitoisuus",
  "tuotantotiedot.vastaanotto.field.ILuokanNormimaidonHinta": "I-luokan normimaidon hinta",
  "tuotantotiedot.vastaanotto.field.KuukaudenAikanaVastaanotetustaMaidostaMaksettuYhteensa": "Kuukauden aikana tuottajilta vastaanotetusta maidosta maksettu rahasumma",
  "tuotantotiedot.vastaanotto.field.EnnakkojalkitiliaMaksettu": "Määrä, josta on maksettu ennakkojälkitiliä",
  "tuotantotiedot.vastaanotto.field.VastaanotetustaMaidostaMaksettuEnnakkojalkitili": "Vastaanotetusta maidosta maksettu ennakkojälkitili",
  "tuotantotiedot.vastaanotto.field.TuottajilleMaksettujaEnnakkojalkitilejaYhteensa": "Tuottajille maksettuja ennakkojälkitilejä yhteensä",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.RasvatonMaitoPastoroitu1ProsJaAlle": "Rasvaton maito pastöroitu 1 % ja alle",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.KevytmaitoPastoroituYli1Pros_3Pros": "Kevytmaito, pastöroitu yli 1 % - 3 %",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.TaysmaitoPastoroituYli3Pros": "Täysmaito, pastöroitu yli 3 %",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.RasvatonMaitoUHT1ProsJaAlle": "Rasvaton maito, UHT 1 % ja alle",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.KevytmaitoUHTYli1Pros_3Pros": "Kevytmaito, UHT yli 1 % - 3 %",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.TaysmaitoUHTYli3Pros": "Täysmaito, UHT yli 3 %",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.RasvatonMaitojuoma1ProsJaAlle": "Rasvaton maitojuoma 1 % ja alle",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.KevytmaitojuomaYli1Pros_3Pros": "Kevytmaitojuoma yli 1 % - 3 %",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.TaysmaitojuomaYli3Pros": "Täysmaitojuoma yli 3 %",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.Piimat": "Piimät",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.KermaYli29Pros": "Kermat, rasvapitoisuus yli 29%",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.Kermat29ProsJaAlle": "Kermat, rasvapitoisuus 29% ja alle",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.Kermaviili": "Kermaviili",
  "tuotantotiedot.juustojamuut.juustotlehmanmaidosta": "Juustot (lehmänmaidosta valmistetut)",
  "tuotantotiedot.juustojamuut.juustotvuohenmaidosta": "Juustot (vuohenmaidosta valmistetut)",
  "tuotantotiedot.juustojamuut.voijarasvaseokset": "Voi ja rasvaseokset",
  "tuotantotiedot.juustojamuut.kaseiinijakaseinaatit": "Kaseiini ja kaseinaatit",
  "tuotantotiedot.juustojamuut.laktoosi": "Laktoosi",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.Ranskankerma": "Ranskankerma",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.Smetana": "Smetana",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.ViiliEiSisLisaaineita": "Viili, lisäaineeton",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.ViiliSisLisaaineita": "Viili, sis. lisäaineita",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.JogurttiEiSisLisaaineita": "Jogurtti, lisäaineeton",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.JogurttiSisLisaaineita": "Jogurtti, sis. lisäaineita",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.RahkaMaustamaton": "Rahka, maustamaton",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.RahkaMaustettu": "Rahka, maustettu",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.Vanukkaat": "Vanukkaat",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.MaitoJaHerapohjJuomat": "Maito- ja herapohj. juomat",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.MuutTuoretuotteet": "Muut tuoretuotteet",
  "tuotantotiedot.juustojamuut.jauheetjapiimät.title": "Jauheet",
  "tuotantotiedot.juustojamuut.field.KypsytetytJuustot": "Kypsytetyt juustot",
  "tuotantotiedot.juustojamuut.field.Raejuusto": "Raejuusto",
  "tuotantotiedot.juustojamuut.field.Tuorejuustot": "Tuorejuustot",
  "tuotantotiedot.juustojamuut.field.Sulatejuustot": "Sulatejuustot",
  "tuotantotiedot.juustojamuut.field.Leipajuusto": "Leipäjuusto",
  "tuotantotiedot.juustojamuut.field.Kutunjuusto": "Juustot (vuohenmaidosta valmistetut)",
  "tuotantotiedot.juustojamuut.field.VoiRasvapit80ProsJaYli": "Voi, rasvapitoisuus 80% ja yli",
  "tuotantotiedot.juustojamuut.field.VoiRasvapitAlle80Pros": "Voi, rasvapitoituus alle 80%",
  "tuotantotiedot.juustojamuut.field.Rasvaseokset": "Rasvaseokset",
  "tuotantotiedot.juustojamuut.field.RasvaseoksetJostaMaitorasvaa": "...josta maitorasvaa",
  "tuotantotiedot.juustojamuut.field.VoiOljy": "Voiöljy",
  "tuotantotiedot.juustojamuut.field.VoiOljySuoraanKermasta": "Voiöljy josta voiöljy suoraan kermasta",
  "tuotantotiedot.juustojamuut.field.RasvainenMaitojauhe": "Rasvainen maitojauhe",
  "tuotantotiedot.juustojamuut.field.RasvatonMaitojauhe": "Rasvaton maitojauhe",
  "tuotantotiedot.juustojamuut.field.KirnupiimaJaKirnumaitojauheet": "Kirnupiimä- ja kirnumaitojauheet",
  "tuotantotiedot.juustojamuut.field.Herajauheet": "Herajauheet",
  "tuotantotiedot.juustojamuut.field.MuutHeraMaitoJaSeosjauheet": "Muut hera- , maito- ja seosjauheet",
  "tuotantotiedot.juustojamuut.field.Laktoosi": "Laktoosi",
  "tuotantotiedot.juustojamuut.field.KaseiiniJaKaseinaatit": "Kaseiini ja kaseinaatit",
  "tuotantotiedot.juustojamuut.varastot.title": "Juuston ja voin varastot (kuukauden viimeinen päivä)",
  "tuotantotiedot.juustojamuut.field.VarastointiKypsytetytJuustot": "Kypsytetyt juustot",
  "tuotantotiedot.juustojamuut.field.VarastointiVoi": "Voi",
  "tuotantotiedot.maitoraakaaineenvalmistus.title": "Maitoraaka-aineen valmistus",
  "tuotantotiedot.maitoraakaaineenvalmistus.ohjeteksti": "Ilmoita meijerissä valmistetun maitoraaka-aineen määrä, joka on käytetty tai myyty jäätelön valmistukseen. Tässä ilmoitetaan myös maitoraaka-aine, joka on toimitettu meijeriteollisuuden ulkopuoliselle elintarviketeollisuudelle maitoraaka-aineeksi esimerkiksi suklaan valmistukseen.",
  "tuotantotiedot.maitoraakaaineenvalmistus.field.RaakaaineRasvatonMaito": "Rasvaton maito",
  "tuotantotiedot.maitoraakaaineenvalmistus.field.RaakaaineMaito": "Maito",
  "tuotantotiedot.maitoraakaaineenvalmistus.field.RaakaaineKerma": "Kerma",
  "tuotantotiedot.juustomyynti.title": "Juuston kotimaan myynti (myynti suoraan kulutukseen)",
  "tuotantotiedot.juustomyynti.field.KypsytetytJuustotJaSulatejuusto": "Kypsytetyt juustot ja sulatejuustot",
  "tuotantotiedot.juustomyynti.field.Tuorejuustot": "Tuorejuustot",
  "tuotantotiedot.juustomyynti.field.Leipäjuusto": "Leipäjuusto",
  "jälkitilit.title": "Jälkitilit",
  "jälkitilit.description": "Tallenna yrityksesi vuosittaiset jälkitilit.",
  "jälkitilit.jälkitili.title": "Jälkitili",
  "jälkitilit.jälkitili.ohjeteksti": "Ilmoita edellisenä vuonna tuottajilta vastaanotetusta maidosta maksettu jälkitili.",
  "jälkitilit.jälkitili.field.JälkitiliMaitomäärä": "Jälkitilin maitomäärä",
  "jälkitilit.jälkitili.field.JälkitiliTuottajilleMaksettu": "Tuottajille maksettu jälkitili ilman alv:a",
  "jälkitilit.jälkitili.field.JälkitiliTuottajilleMaksettuYhteensä": "Kaikille tuottajille maksettu jälkitili yhteensä ilman alv:a",
  "jälkitilit.jälkitili.field.JälkitiliMaitomääräYhteensä": "Meijerin vastaanottama maitomäärä vuoden aikana",
  "jälkitilit.muutvuosittaisetlisät.title": "Muut vuosittaiset lisät",
  "jälkitilit.muutvuosittaisetlisät.ohjeteksti": "Ilmoita kaikki muut mahdolliset edelliseen vuoteen kohdistuvat, kerran tai muutamia kertoja maksetut lisät, jotka eivät ole sisältyneet kuukausikyselyssä ilmoitettuihin lukuihin.",
  "jälkitilit.muutvuosittaisetlisät.lisät1": "Maksettu lisä 1",
  "jälkitilit.muutvuosittaisetlisät.lisät2": "Maksettu lisä 2",
  "jälkitilit.muutvuosittaisetlisät.field.MuutVuosittaisetLisätTeksti": "Muu maksettu lisä, mikä?",
  "jälkitilit.muutvuosittaisetlisät.field.MuutVuosittaisetLisätMaitomäärä": "Maitomäärät, jota muut vuosittaiset lisät koskevat",
  "jälkitilit.muutvuosittaisetlisät.field.MuutVuosittaisetLisätTuottajilleVuosittain": "Muut vuosittain maksetut lisät tuottajille, ilman alv:a",
  "jälkitilit.muutvuosittaisetlisät.field.MuutVuosittaisetLisätTuottajilleYhteensä": "Yhteensä tuottajille maksetut vuosittaiset lisät, ilman alv:a",
  "valmis.kiitos": "Kiitos vastauksistasi!",
  "valmis.p": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce iaculis nec metus sit amet dignissim.",
  "valmis.uusi": "Valitse uusi lomake",
  "footer.saavutettavuus": "Saavutettavuusseloste",
  "footer.maitotilastot": "Maito- ja maitotuotetilasto",
  "footer.tuottajahinnat": "Maataloustuotteiden tuottajahinnat",
  "luomumaitotuotteidenvalmistus.title": "Luomumaitotuotteiden valmistus",
  "luomumaitotuotteidenvalmistus.description": "Tallenna yrityksesi luomumaitotuotteiden valmistusmäärät.",
  "luomumaitotuotteidenvalmistus.field.EiLuomumaitotuotteidenValmistusta": "Yrityksellä ei ole ollut luomumaitotuotteiden valmistusta.",
  "luomumaitotuotteidenvalmistus.field.LuomumaitotuotteidenValmistusMaidot": "Maidot",
  "luomumaitotuotteidenvalmistus.field.LuomumaitotuotteidenValmistusKermat": "Kermat",
  "luomumaitotuotteidenvalmistus.field.LuomumaitotuotteidenValmistusPiimat": "Piimät",
  "luomumaitotuotteidenvalmistus.field.LuomumaitotuotteidenValmistusJogurtit": "Jogurtit",
  "luomumaitotuotteidenvalmistus.field.LuomumaitotuotteidenValmistusViilit": "Viilit",
  "luomumaitotuotteidenvalmistus.field.LuomumaitotuotteidenValmistusVoi": "Voi",
  "luomumaitotuotteidenvalmistus.field.LuomumaitotuotteidenValmistusJuusto": "Juustot",
  "luomumaitotuotteidenvalmistus.field.LuomumaitotuotteidenValmistusMuut": "Muut tuotteet",
  "juustojenvalmistus.title": "Juustojen valmistus",
  "juustojenvalmistus.description": "Tallenna yrityksesi juustojen valmistusmäärät.",
  "juustojenvalmistus.field.pehmeaJuusto": "Pehmeä juusto (MFFB jalostettuna vähintään 68 %)",
  "juustojenvalmistus.field.PuolipehmeaJuusto": "Puolipehmeä juusto (MFFB jalostettuna vähintään 62 %, mutta pienempi kuin 68 %)",
  "juustojenvalmistus.field.puolikovaJuusto": "Puolikova juusto (MFFB jalostettuna vähintään 55 %, mutta pienempi kuin 62 %)",
  "juustojenvalmistus.field.kovaJuusto": "Kova juusto (MFFB jalostettuna vähintään 47 %, mutta pienempi kuin 55 %) ",
  "juustojenvalmistus.field.erittainKovaJuusto": "Erittäin kova juusto (MFFB jalostettuna pienempi kuin 47 %) ",
  "juustojenvalmistus.field.tuorejuusto": "Tuorejuusto"
}
