import axios from "axios";
import _ from "lodash";
import { useAsync } from "react-async-hook";
import {
  UseFormGetValues,
  UseFormReset,
  UseFormSetError,
} from "react-hook-form";

export default function useData(
  id: string | null,
  reset: UseFormReset<any>,
  setError: UseFormSetError<any>,
  getValues: UseFormGetValues<any>
) {
  const endpoint =
    (process.env.REACT_APP_DATA_API_URL + "/data" || "localhost:3000/data") +
    `?id=${id}`;

  /**
   * Get data from backend on hook mount
   */
  const getData = useAsync(async () => {
    const res = await axios.get(endpoint);

    reset(res.data);
  }, []);

  /**
   * Update data to backend, hook returns this function
   */
  const update = useAsync(
    async () => {
      try {
        // Remove empty values from payload before sending it
        await axios.put(
          endpoint,
          _.omitBy(getValues(), (v) => _.isUndefined(v) || v === "")
        );
      } catch (e: any) {
        // Set errors from response if exist
        if (e && e.errors && e.errors[0]) {
          setError(e.path, { message: e.errors[0] });
        }
        // Throw so component level catches that request failed
        throw e;
      }
    },
    [],
    { executeOnMount: false }
  );

  // Loading true get or put requests are active
  // Error object is set from put requests only
  return {
    update,
    loading: getData.loading || update.loading,
    error: update.error,
  };
}
