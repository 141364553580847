import { useTranslation } from "react-i18next";

export default function usePeriodTitle() {
  const { t } = useTranslation();

  const trTitle = (title: string) => {
    if (!title) return title;

    const parts = title.split(" ");
    if (parts.length > 1) {
      return t(`common.${parts[0]}`) + " " + parts[1];
    } else return title;
  };

  return trTitle;
}
