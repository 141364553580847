import axios from "axios";
import { useState } from "react";
import { useAsync } from "react-async-hook";

export default function usePreviousValues(id: string) {
  const [values, setValues] = useState({} as any);

  const endpoint =
    (process.env.REACT_APP_DATA_API_URL + "/data" || "localhost:3000/data") +
    `/${id}/previous`;

  /**
   * Get data from backend on hook mount
   */
  useAsync(async () => {
    const res = await axios.get(endpoint);

    if (res.data) {
      setValues(res.data);
    }
  }, []);

  return values;
}
