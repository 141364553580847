import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { setToken } from "../../store/auth/actions";

export default function useTokenCookieParser() {
  const dispatch = useDispatch();
  const token = Cookies.get("TikalToken");

  if (token) {
    dispatch(setToken(token));
    Cookies.remove("TikalToken", { domain: ".luke.fi" });
  }
}
