import _extends from "@babel/runtime/helpers/esm/extends";
import * as React from 'react';
import { unstable_useForkRef as useForkRef, unstable_useId as useId } from '@mui/utils';
import { ActionTypes } from './types';
import defaultReducer from './defaultListboxReducer';
import useControllableReducer from './useControllableReducer';
import areArraysEqual from '../utils/areArraysEqual';

const defaultOptionComparer = (optionA, optionB) => optionA === optionB;

export default function useListbox(props) {
  var _props$optionIdGenera, _options$highlightedI;

  const {
    disableListWrap = false,
    disabledItemsFocusable = false,
    id: idProp,
    options,
    multiple = false,
    isOptionDisabled = () => false,
    optionComparer = defaultOptionComparer,
    stateReducer: externalReducer,
    listboxRef: externalListboxRef
  } = props;
  const id = useId(idProp);

  function defaultIdGenerator(_, index) {
    return `${id}-option-${index}`;
  }

  const optionIdGenerator = (_props$optionIdGenera = props.optionIdGenerator) != null ? _props$optionIdGenera : defaultIdGenerator;

  const propsWithDefaults = _extends({}, props, {
    disableListWrap,
    disabledItemsFocusable,
    isOptionDisabled,
    multiple,
    optionComparer
  });

  const listboxRef = React.useRef(null);
  const handleRef = useForkRef(externalListboxRef, listboxRef);
  const [{
    highlightedIndex,
    selectedValue
  }, dispatch] = useControllableReducer(defaultReducer, externalReducer, propsWithDefaults);
  const previousOptions = React.useRef([]);
  React.useEffect(() => {
    if (areArraysEqual(previousOptions.current, options, optionComparer)) {
      return;
    }

    dispatch({
      type: ActionTypes.optionsChange,
      options,
      previousOptions: previousOptions.current,
      props: propsWithDefaults
    });
    previousOptions.current = options; // No need to re-run this effect if props change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, optionComparer, dispatch]);

  const createHandleOptionClick = (option, other) => event => {
    var _other$onClick;

    (_other$onClick = other.onClick) == null ? void 0 : _other$onClick.call(other, event);

    if (event.defaultPrevented) {
      return;
    }

    event.preventDefault();
    dispatch({
      type: ActionTypes.optionClick,
      option,
      event,
      props: propsWithDefaults
    });
  };

  const createHandleKeyDown = other => event => {
    var _other$onKeyDown;

    (_other$onKeyDown = other.onKeyDown) == null ? void 0 : _other$onKeyDown.call(other, event);

    if (event.defaultPrevented) {
      return;
    }

    const keysToPreventDefault = [' ', 'Enter', 'ArrowUp', 'ArrowDown', 'Home', 'End', 'PageUp', 'PageDown'];

    if (keysToPreventDefault.includes(event.key)) {
      event.preventDefault();
    }

    dispatch({
      type: ActionTypes.keyDown,
      event,
      props: propsWithDefaults
    });
  };

  const createHandleBlur = other => event => {
    var _other$onBlur, _listboxRef$current;

    (_other$onBlur = other.onBlur) == null ? void 0 : _other$onBlur.call(other, event);

    if (event.defaultPrevented) {
      return;
    }

    if ((_listboxRef$current = listboxRef.current) != null && _listboxRef$current.contains(document.activeElement)) {
      // focus is within the listbox
      return;
    }

    dispatch({
      type: ActionTypes.blur,
      event,
      props: propsWithDefaults
    });
  };

  const getRootProps = (other = {}) => {
    return _extends({}, other, {
      'aria-activedescendant': highlightedIndex >= 0 ? optionIdGenerator(options[highlightedIndex], highlightedIndex) : undefined,
      id,
      onBlur: createHandleBlur(other),
      onKeyDown: createHandleKeyDown(other),
      role: 'listbox',
      tabIndex: 0,
      ref: handleRef
    });
  };

  const getOptionState = option => {
    let selected;
    const index = options.findIndex(opt => optionComparer(opt, option));

    if (multiple) {
      var _ref;

      selected = ((_ref = selectedValue) != null ? _ref : []).some(value => value != null && optionComparer(option, value));
    } else {
      selected = optionComparer(option, selectedValue);
    }

    const disabled = isOptionDisabled(option, index);
    return {
      selected,
      disabled,
      highlighted: highlightedIndex === index
    };
  };

  const getOptionProps = (option, other = {}) => {
    const {
      selected,
      disabled
    } = getOptionState(option);
    const index = options.findIndex(opt => optionComparer(opt, option));
    return {
      'aria-disabled': disabled || undefined,
      'aria-selected': selected,
      id: optionIdGenerator(option, index),
      onClick: createHandleOptionClick(option, other),
      role: 'option'
    };
  };

  return {
    getRootProps,
    getOptionProps,
    getOptionState,
    selectedOption: selectedValue,
    highlightedOption: (_options$highlightedI = options[highlightedIndex]) != null ? _options$highlightedI : null
  };
}