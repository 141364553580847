import { Main } from "@lukefi-private/tikal-storybook";
import { ThemeProvider } from "@mui/material/styles";
import axios, { AxiosRequestConfig } from "axios";
import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "regenerator-runtime/runtime";
import Router from "./components/Router/Router";
import { persistor, store } from "./store";
import theme from "./theme";

axios.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = store.getState().auth.token;
  config.headers.Authorization = token;
  return config;
});

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Main>
            <Router />
          </Main>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}
