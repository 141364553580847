import axios from "axios";
import { useAsync } from "react-async-hook";
import { useDispatch, useSelector } from "react-redux";
import { setInquiries } from "../../store/inquiries/actions";
import RootState from "../../store/types";

export default function useInquiries() {
  const dispatch = useDispatch();
  const endpoint =
    process.env.REACT_APP_DATA_API_URL + "/inquiries" ||
    "localhost:3007/inquiries";

  const inquiries = useSelector((state: RootState) => state.inquiries);

  const getData = useAsync(async () => {
    const res = await axios.get(endpoint);

    dispatch(setInquiries(res.data));
  }, []);

  return { inquiries: inquiries || [], loading: getData.loading };
}
