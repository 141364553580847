import { createTheme, Theme } from "@mui/material/styles";

declare module "@mui/styles/defaultTheme" {
  interface DefaultTheme extends Theme {}
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#007B9A",
      contrastText: "#fff",
    },
    secondary: { main: "#fff", contrastText: "#007B9A" },
    action: {
      disabledBackground: "#0033a0",
      disabled: "#fff",
    },
  },
  typography: {
    fontFamily: "Titillium Web",
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          color: "black",
          textDecoration: "underline",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: "#0033a0",
        },
      },
    },
    MuiCardActionArea: {
      styleOverrides: {
        root: {
          height: "100%",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          height: "100%",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: "0",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        shrink: {
          color: "black",
          "&.Mui-disabled": {
            color: "black",
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          marginTop: "24px",
          marginRight: "-1px",
          marginBottom: "24px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "0",
          textTransform: "none",
        },
        containedSecondary: {
          borderRadius: "3px",
        },
      },
    },

    MuiTypography: {
      styleOverrides: {
        h1: {
          fontSize: "1.5rem",
          lineHeight: 1.334,
          fontWeight: 400,
        },
        h2: {
          fontSize: "1.25rem",
          lineHeight: 1.6,
          fontWeight: 400,
        },
        h3: {
          fontSize: "1.10rem",
          marginBottom: "10px",
        },
        subtitle2: {
          display: "block",
        },
        body1: {
          marginBottom: "10px",
          fontSize: "1.02rem",
          "&:last-child": {
            marginBottom: "0px",
          },
          "&:first-of-type": {
            marginTop: "0px",
          },
        },
      },
    },
  },
});

export default theme;
