import { yupResolver } from "@hookform/resolvers/yup";
import {
  Fade,
  FormActions,
  FormInstructions,
  NextButton,
  PrevButton,
} from "@lukefi-private/tikal-storybook";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { useData, useQuery } from "../common";
import { KgField } from "../common/Fields";
import { nullableNumber } from "../common/FormUtils";
import useTokenMetadata from "../common/useTokenMetadata";

const useStyles = makeStyles(() => ({
  formTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

type TuoretuotteidenValmistusForm = {
  RasvatonMaitoPastoroitu1ProsJaAlle: number;
  KevytmaitoPastoroituYli1Pros_3Pros: number;
  TaysmaitoPastoroituYli3Pros: number;
  RasvatonMaitoUHT1ProsJaAlle: number;
  KevytmaitoUHTYli1Pros_3Pros: number;
  TaysmaitoUHTYli3Pros: number;
  RasvatonMaitojuoma1ProsJaAlle: number;
  KevytmaitojuomaYli1Pros_3Pros: number;
  TaysmaitojuomaYli3Pros: number;
  Piimat: number;
  KermaYli29Pros: number;
  Kermat29ProsJaAlle: number;
  Kermaviili: number;
  Smetana: number;
  Ranskankerma: number;
  ViiliEiSisLisaaineita: number;
  ViiliSisLisaaineita: number;
  JogurttiEiSisLisaaineita: number;
  JogurttiSisLisaaineita: number;
  RahkaMaustamaton: number;
  RahkaMaustettu: number;
  Vanukkaat: number;
  MaitoJaHerapohjJuomat: number;
  MuutTuoretuottee: number;
};

export default function TuoretuotteidenValmistus() {
  const query = useQuery();
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();

  const methods = useForm<TuoretuotteidenValmistusForm>({
    shouldFocusError: true,
    reValidateMode: "onBlur",
    resolver: yupResolver(
      yup.object().shape({
        RasvatonMaitoPastoroitu1ProsJaAlle: nullableNumber,
        KevytmaitoPastoroituYli1Pros_3Pros: nullableNumber,
        TaysmaitoPastoroituYli3Pros: nullableNumber,
        RasvatonMaitoUHT1ProsJaAlle: nullableNumber,
        KevytmaitoUHTYli1Pros_3Pros: nullableNumber,
        TaysmaitoUHTYli3Pros: nullableNumber,
        RasvatonMaitojuoma1ProsJaAlle: nullableNumber,
        KevytmaitojuomaYli1Pros_3Pros: nullableNumber,
        TaysmaitojuomaYli3Pros: nullableNumber,
        Piimat: nullableNumber,
        KermaYli29Pros: nullableNumber,
        Kermat29ProsJaAlle: nullableNumber,
        Kermaviili: nullableNumber,
        Smetana: nullableNumber,
        Ranskankerma: nullableNumber,
        ViiliEiSisLisaaineita: nullableNumber,
        ViiliSisLisaaineita: nullableNumber,
        JogurttiEiSisLisaaineita: nullableNumber,
        JogurttiSisLisaaineita: nullableNumber,
        RahkaMaustamaton: nullableNumber,
        RahkaMaustettu: nullableNumber,
        Vanukkaat: nullableNumber,
        MaitoJaHerapohjJuomat: nullableNumber,
        MuutTuoretuotteet: nullableNumber,
      })
    ),
  });

  const luomumaitoOsanotto = useTokenMetadata("LUOMUMAIDON_VASTAANOTTO");

  const { update, loading } = useData(
    query.get("id"),
    methods.reset,
    methods.setError,
    methods.getValues
  );

  const onSubmit = async () => {
    try {
      // Push data to backend, without parameters because hook is aware of form state
      await update.execute();

      // Navigate to next page
      history.push(
        `/maidontuotanto/lomake/juusto-ja-muut-maitotuotteet-valmistus-varastot?id=${query.get(
          "id"
        )}`
      );
    } catch (e) {
      console.error(e);
    }
  };

  /** Submit data on component unmount */
  useEffect(() => {
    return () => {
      update.execute();
    };
  }, []);

  const handlePreviousClick = () => {
    if (luomumaitoOsanotto) {
      history.push(
        `/maidontuotanto/lomake/luomumaidon-vastaanotto?id=${query.get("id")}`
      );
    } else {
      history.push(
        `/maidontuotanto/lomake/maidon-vastaanotto?id=${query.get("id")}`
      );
    }
  };

  return (
    <Fade>
      <FormProvider {...methods}>
        <Box>
          <Box className={classes.formTitle}>
            <Typography variant="h2">
              {"2. " + t("tuotantotiedot.tuoretuotteidenvalmistus.title")}
            </Typography>
            <Typography>
              <a
                target="_blank"
                rel="noreferrer noopener"
                // eslint-disable-next-line max-len
                href={t("kirjaudu.ohjeteksti2.linkki")}
              >
                {t("common.lomake.ohjelinkki.teksti")}
              </a>
            </Typography>
          </Box>

          <Box p={2} pt={0}>
            <FormInstructions>
              <Typography variant="body1">
                {t("tuotantotiedot.tuoretuotteidenvalmistus.ohjeteksti")}
              </Typography>
            </FormInstructions>
            <Typography variant="h3">
              {t("tuotantotiedot.tuoretuotteidenvalmistus.maito")}
            </Typography>
            <KgField
              name="RasvatonMaitoPastoroitu1ProsJaAlle"
              label={t(
                "tuotantotiedot.tuoretuotteidenvalmistus.field.RasvatonMaitoPastoroitu1ProsJaAlle"
              )}
              showPreviousLabel={true}
            />
            <KgField
              name="KevytmaitoPastoroituYli1Pros_3Pros"
              label={t(
                "tuotantotiedot.tuoretuotteidenvalmistus.field.KevytmaitoPastoroituYli1Pros_3Pros"
              )}
            />
            <KgField
              name="TaysmaitoPastoroituYli3Pros"
              label={t(
                "tuotantotiedot.tuoretuotteidenvalmistus.field.TaysmaitoPastoroituYli3Pros"
              )}
            />
            <KgField
              name="RasvatonMaitoUHT1ProsJaAlle"
              label={t(
                "tuotantotiedot.tuoretuotteidenvalmistus.field.RasvatonMaitoUHT1ProsJaAlle"
              )}
            />
            <KgField
              name="KevytmaitoUHTYli1Pros_3Pros"
              label={t(
                "tuotantotiedot.tuoretuotteidenvalmistus.field.KevytmaitoUHTYli1Pros_3Pros"
              )}
            />
            <KgField
              name="TaysmaitoUHTYli3Pros"
              label={t(
                "tuotantotiedot.tuoretuotteidenvalmistus.field.TaysmaitoUHTYli3Pros"
              )}
            />
            <KgField
              name="RasvatonMaitojuoma1ProsJaAlle"
              label={t(
                "tuotantotiedot.tuoretuotteidenvalmistus.field.RasvatonMaitojuoma1ProsJaAlle"
              )}
            />
            <KgField
              name="KevytmaitojuomaYli1Pros_3Pros"
              label={t(
                "tuotantotiedot.tuoretuotteidenvalmistus.field.KevytmaitojuomaYli1Pros_3Pros"
              )}
            />
            <KgField
              name="TaysmaitojuomaYli3Pros"
              label={t(
                "tuotantotiedot.tuoretuotteidenvalmistus.field.TaysmaitojuomaYli3Pros"
              )}
            />
            <Box mt={2}>
              <Typography variant="h3">Piimät</Typography>
              <KgField
                name="Piimat"
                label={t(
                  "tuotantotiedot.tuoretuotteidenvalmistus.field.Piimat"
                )}
              />
            </Box>
            <Box mt={2}>
              <Typography variant="h3">
                {t("tuotantotiedot.tuoretuotteidenvalmistus.kermat")}
              </Typography>
              <KgField
                name="KermaYli21Pros"
                label={t(
                  "tuotantotiedot.tuoretuotteidenvalmistus.field.KermaYli21Pros"
                )}
              />
              <KgField
                name="Kermat21ProsJaAlle"
                label={t(
                  "tuotantotiedot.tuoretuotteidenvalmistus.field.Kermat21ProsJaAlle"
                )}
              />
            </Box>
            <Box mt={2}>
              <Typography variant="h3">
                {t(
                  "tuotantotiedot.tuoretuotteidenvalmistus.hapatetutkermavalmisteet"
                )}
              </Typography>
              <KgField
                name="Kermaviili"
                label={t(
                  "tuotantotiedot.tuoretuotteidenvalmistus.field.Kermaviili"
                )}
              />
              <KgField
                name="Smetana"
                label={t(
                  "tuotantotiedot.tuoretuotteidenvalmistus.field.Smetana"
                )}
              />
              <KgField
                name="Ranskankerma"
                label={t(
                  "tuotantotiedot.tuoretuotteidenvalmistus.field.Ranskankerma"
                )}
              />
            </Box>
            <Box mt={2}>
              <Typography variant="h3">
                {t("tuotantotiedot.tuoretuotteidenvalmistus.viilit")}
              </Typography>
              <KgField
                name="ViiliEiSisLisaaineita"
                label={t(
                  "tuotantotiedot.tuoretuotteidenvalmistus.field.ViiliEiSisLisaaineita"
                )}
              />
              <KgField
                name="ViiliSisLisaaineita"
                label={t(
                  "tuotantotiedot.tuoretuotteidenvalmistus.field.ViiliSisLisaaineita"
                )}
              />
            </Box>
            <Box mt={2}>
              <Typography variant="h3">
                {t("tuotantotiedot.tuoretuotteidenvalmistus.jogurtit")}
              </Typography>
              <KgField
                name="JogurttiEiSisLisaaineita"
                label={t(
                  "tuotantotiedot.tuoretuotteidenvalmistus.field.JogurttiEiSisLisaaineita"
                )}
              />
              <KgField
                name="JogurttiSisLisaaineita"
                label={t(
                  "tuotantotiedot.tuoretuotteidenvalmistus.field.JogurttiSisLisaaineita"
                )}
              />
            </Box>
            <Box mt={2}>
              <Typography variant="h3">
                {t("tuotantotiedot.tuoretuotteidenvalmistus.rahka")}
              </Typography>
              <KgField
                name="RahkaMaustamaton"
                label={t(
                  "tuotantotiedot.tuoretuotteidenvalmistus.field.RahkaMaustamaton"
                )}
              />
              <KgField
                name="RahkaMaustettu"
                label={t(
                  "tuotantotiedot.tuoretuotteidenvalmistus.field.RahkaMaustettu"
                )}
              />
            </Box>
            <Box mt={2}>
              <Typography variant="h3">
                {t("tuotantotiedot.tuoretuotteidenvalmistus.vanukkaat")}
              </Typography>
              <KgField
                name="Vanukkaat"
                label={t(
                  "tuotantotiedot.tuoretuotteidenvalmistus.field.Vanukkaat"
                )}
              />
            </Box>
            <Box mt={2}>
              <Typography variant="h3">
                {t(
                  "tuotantotiedot.tuoretuotteidenvalmistus.maitojaherapohjaisetjuomat"
                )}
              </Typography>
              <FormInstructions>
                <Typography variant="body1">
                  {t("tuotantotiedot.tuoretuotteidenvalmistus.ohjeteksti2")}
                </Typography>
              </FormInstructions>
              <KgField
                name="MaitoJaHerapohjJuomat"
                label={t(
                  "tuotantotiedot.tuoretuotteidenvalmistus.field.MaitoJaHerapohjJuomat"
                )}
              />
            </Box>
            <Box mt={2}>
              <Typography variant="h3">
                {t("tuotantotiedot.tuoretuotteidenvalmistus.muuttuoretuotteet")}
              </Typography>
              <FormInstructions>
                <Typography variant="body1">
                  {t("tuotantotiedot.tuoretuotteidenvalmistus.ohjeteksti3")}
                </Typography>
              </FormInstructions>
              <KgField
                name="MuutTuoretuotteet"
                label={t(
                  "tuotantotiedot.tuoretuotteidenvalmistus.field.MuutTuoretuotteet"
                )}
              />
            </Box>
            <FormActions>
              <PrevButton onClick={handlePreviousClick}></PrevButton>
              <NextButton
                disabled={loading}
                onClick={methods.handleSubmit(onSubmit)}
              ></NextButton>
            </FormActions>
          </Box>
        </Box>
      </FormProvider>
    </Fade>
  );
}
