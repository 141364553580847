import axios from "axios";
import { useState } from "react";
import { useAsync } from "react-async-hook";

export default function usePeriod(id: string) {
  const [period, setPeriod] = useState(null as any);

  const endpoint =
    process.env.REACT_APP_DATA_API_URL + "/periods/" + id ||
    "localhost:3007/periods/" + id;

  const getData = useAsync(async () => {
    if (id) {
      const res = await axios.get(endpoint);
      setPeriod(res.data);
    }
  }, []);

  return { period, loading: getData.loading };
}
