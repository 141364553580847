var ActionTypes; // split declaration and export due to https://github.com/codesandbox/codesandbox-client/issues/6435

(function (ActionTypes) {
  ActionTypes["blur"] = "blur";
  ActionTypes["focus"] = "focus";
  ActionTypes["keyDown"] = "keyDown";
  ActionTypes["optionClick"] = "optionClick";
  ActionTypes["setControlledValue"] = "setControlledValue";
  ActionTypes["optionsChange"] = "optionsChange";
})(ActionTypes || (ActionTypes = {}));

export { ActionTypes };