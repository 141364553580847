import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Accessibility from "../Accessibility/Accessibility";
import GuardedRoute from "../common/GuardedRoute";
import ScrollReset from "../common/ScrollReset";
import useTokenCookieParser from "../common/useTokenCookieParser";
import JuustojenValmistus from "../JuustojenValmistus/Aloitus";
import JuustojenValmistusRouter from "../JuustojenValmistus/JuustojenValmistusRouter";
import Jälkitili from "../Jälkitilit/Aloitus";
import JälkitilitRouter from "../Jälkitilit/JälkitilitRouter";
import { Kirjaudu, loginError } from "../Kirjaudu";
import LuomumaitotuotteidenValmistus from "../LuomumaitotuotteidenValmistus/Aloitus";
import LuomumaitotuotteidenValmistusRouter from "../LuomumaitotuotteidenValmistus/LuomumaitotuotteidenValmistusRouter";
import Maidontuotanto from "../Maidontuotanto/Aloitus";
import MaidontuotantoRouter from "../Maidontuotanto/MaidontuotantoRouter";
import { ValitseTiedonkeruu } from "../ValitseTiedonkeruu";
import RedirectsEditors from "./RedirectsEditors";

export default function Router() {
  useTokenCookieParser();

  return (
    <BrowserRouter>
      <ScrollReset>
        <Switch>
          <Route exact path="/" component={Kirjaudu}></Route>
          <Route
            exact
            path="/tunnistautuminen-epaonnistui"
            component={loginError}
          ></Route>
          <Route exact path="/saavutettavuus" component={Accessibility}></Route>
          <GuardedRoute path="/aloitus" component={ValitseTiedonkeruu} />
          <GuardedRoute
            path="/maidontuotanto/lomake"
            component={MaidontuotantoRouter}
          />
          <GuardedRoute
            path="/jälkitilit/lomake"
            component={JälkitilitRouter}
          />
          <GuardedRoute
            path="/luomumaitotuotteiden-valmistus/lomake"
            component={LuomumaitotuotteidenValmistusRouter}
          />
          <GuardedRoute path="/jälkitilit" component={Jälkitili} />
          <GuardedRoute path="/maidontuotanto" component={Maidontuotanto} />
          <GuardedRoute
            path="/luomumaitotuotteiden-valmistus"
            component={LuomumaitotuotteidenValmistus}
          />
          <GuardedRoute
            path="/juustojen-valmistus/lomake"
            component={JuustojenValmistusRouter}
          />
          <GuardedRoute
            path="/juustojen-valmistus"
            component={JuustojenValmistus}
          />

          <Route path="/editor" component={RedirectsEditors} />
          <Redirect from="*" to="/" />
        </Switch>
      </ScrollReset>
    </BrowserRouter>
  );
}
