import { yupResolver } from "@hookform/resolvers/yup";
import {
  Divider,
  Fade,
  FormActions,
  FormInstructions,
  NextButton,
  PrevButton,
} from "@lukefi-private/tikal-storybook";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { useData, useQuery } from "../common";
import { KgField } from "../common/Fields";
import { nullableNumber } from "../common/FormUtils";

const useStyles = makeStyles(() => ({
  formTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

type JuustonKotimaanMyyntiForm = {
  KypsytetytJuustot: number;
  Raejuusto: number;
  Sulatejuustot: number;
  Tuorejuustot: number;
  Leipajuusto: number;
  Kutunjuusto: number;
  VoiRasvapit80ProsJaYli: number;
  VoiRasvapitAlle80Pros: number;
  Rasvaseokset: number;
  RasvaseoksetJostaMaitorasvaa: number;
  VoiOljy: number;
  RasvainenMaitojauhe: number;
  RasvatonMaitojauhe: number;
  KirnupiimaJaKirnumaitojauheet: number;
  Herajauheet: number;
  MuutHeraMaitoJaSeosjauheet: number;
  Laktoosi: number;
  KaseiiniJaKaseinaatit: number;
  KypsytetytJuustotSuoraanKulutukseen: number;
  TuorejuustoSuoraanKulutukseen: number;
  LeipäjuustoSuoraanKulutukseen: number;
  KypsytetytJuustotVarasto: number;
  VoiVarasto: number;
};

export default function JuustonKotimaanMyyntiTiedot() {
  const query = useQuery();
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();

  const methods = useForm<JuustonKotimaanMyyntiForm>({
    shouldFocusError: true,
    reValidateMode: "onBlur",
    resolver: yupResolver(
      yup.object().shape({
        KypsytetytJuustot: nullableNumber,
        Tuorejuustot: nullableNumber,
        Raejuusto: nullableNumber,
        Sulatejuustot: nullableNumber,
        Leipajuusto: nullableNumber,
        Kutunjuusto: nullableNumber,
        VoiRasvapit80ProsJaYli: nullableNumber,
        VoiRasvapitAlle80Pros: nullableNumber,
        Rasvaseokset: nullableNumber,
        RasvaseoksetJostaMaitorasvaa: nullableNumber,
        VoiOljy: nullableNumber,
        RasvainenMaitojauhe: nullableNumber,
        RasvatonMaitojauhe: nullableNumber,
        KirnupiimaJaKirnumaitojauheet: nullableNumber,
        Herajauheet: nullableNumber,
        MuutHeraMaitoJaSeosjauheet: nullableNumber,
        Laktoosi: nullableNumber,
        KaseiiniJaKaseinaatit: nullableNumber,
        KypsytetytJuustotSuoraanKulutukseen: nullableNumber,
        TuorejuustoSuoraanKulutukseen: nullableNumber,
        LeipäjuustoSuoraanKulutukseen: nullableNumber,
        KypsytetytJuustotVarasto: nullableNumber,
        VoiVarasto: nullableNumber,
      })
    ),
  });

  const { update, loading } = useData(
    query.get("id"),
    methods.reset,
    methods.setError,
    methods.getValues
  );

  const onSubmit = async () => {
    try {
      // Push data to backend
      await update.execute();

      // Navigate to next page
      history.push(
        `/maidontuotanto/lomake/maitoraakaaineen-valmistus?id=${query.get(
          "id"
        )}`
      );
    } catch (e) {
      console.error(e);
    }
  };

  /** Submit data on component unmount */
  useEffect(() => {
    return () => {
      update.execute();
    };
  }, []);

  const handlePreviousClick = () =>
    history.push(
      `/maidontuotanto/lomake/tuoretuotteiden-valmistus?id=${query.get("id")}`
    );

  return (
    <Fade>
      <FormProvider {...methods}>
        <Box>
          <Box className={classes.formTitle}>
            <Typography variant="h2">
              {"3. " + t("tuotantotiedot.juustojamuut.title")}
            </Typography>
            <Typography>
              <a
                target="_blank"
                rel="noreferrer noopener"
                // eslint-disable-next-line max-len
                href={t("kirjaudu.ohjeteksti2.linkki")}
              >
                {t("common.lomake.ohjelinkki.teksti")}
              </a>
            </Typography>
          </Box>

          <Box p={2} pt={0}>
            <FormInstructions>
              <Typography variant="body1">
                {t("tuotantotiedot.juustojamuut.ohjeteksti")}
              </Typography>
            </FormInstructions>
            <Typography variant="h3">
              {t("tuotantotiedot.juustojamuut.juustotlehmanmaidosta")}
            </Typography>
            <KgField
              name="KypsytetytJuustot"
              label={t("tuotantotiedot.juustojamuut.field.KypsytetytJuustot")}
              showPreviousLabel={true}
            />
            <KgField
              name="Raejuusto"
              label={t("tuotantotiedot.juustojamuut.field.Raejuusto")}
            />
            <KgField
              name="Leipajuusto"
              label={t("tuotantotiedot.juustojamuut.field.Leipajuusto")}
            />
            <KgField
              name="Tuorejuustot"
              label={t("tuotantotiedot.juustojamuut.field.Tuorejuustot")}
            />
            <KgField
              name="Sulatejuustot"
              label={t("tuotantotiedot.juustojamuut.field.Sulatejuustot")}
            />
            <Divider />
            <Box mt={2}>
              <Typography variant="h3">
                {t("tuotantotiedot.juustojamuut.juustotvuohenmaidosta")}
              </Typography>
              <KgField
                name="Kutunjuusto"
                label={t("tuotantotiedot.juustojamuut.field.Kutunjuusto")}
              />
            </Box>
            <Divider />
            <Box mt={2}>
              <Typography variant="h3">
                {t("tuotantotiedot.juustojamuut.voijarasvaseokset")}
              </Typography>
              <KgField
                name="VoiRasvapit80ProsJaYli"
                label={t(
                  "tuotantotiedot.juustojamuut.field.VoiRasvapit80ProsJaYli"
                )}
              />
              <KgField
                name="VoiRasvapitAlle80Pros"
                label={t(
                  "tuotantotiedot.juustojamuut.field.VoiRasvapitAlle80Pros"
                )}
              />
              <KgField
                name="Rasvaseokset"
                label={t("tuotantotiedot.juustojamuut.field.Rasvaseokset")}
              />
              <KgField
                indent
                name="RasvaseoksetJostaMaitorasvaa"
                label={t(
                  "tuotantotiedot.juustojamuut.field.RasvaseoksetJostaMaitorasvaa"
                )}
              />
              <KgField
                name="VoiOljy"
                label={t("tuotantotiedot.juustojamuut.field.VoiOljy")}
              />
            </Box>
            <Divider />
            <Box mt={2}>
              <Typography variant="h3">
                {t("tuotantotiedot.juustojamuut.jauheetjapiimät.title")}
              </Typography>
              <KgField
                name="RasvainenMaitojauhe"
                label={t(
                  "tuotantotiedot.juustojamuut.field.RasvainenMaitojauhe"
                )}
              />
              <KgField
                name="RasvatonMaitojauhe"
                label={t(
                  "tuotantotiedot.juustojamuut.field.RasvatonMaitojauhe"
                )}
              />
              <KgField
                name="KirnupiimaJaKirnumaitojauheet"
                label={t(
                  "tuotantotiedot.juustojamuut.field.KirnupiimaJaKirnumaitojauheet"
                )}
              />
              <KgField
                name="Herajauheet"
                label={t("tuotantotiedot.juustojamuut.field.Herajauheet")}
              />
              <KgField
                name="MuutHeraMaitoJaSeosjauheet"
                label={t(
                  "tuotantotiedot.juustojamuut.field.MuutHeraMaitoJaSeosjauheet"
                )}
              />
            </Box>
            <Divider />
            <Box mt={2}>
              <Typography variant="h3">
                {t("tuotantotiedot.juustojamuut.kaseiinijakaseinaatit")}
              </Typography>
              <KgField
                name="KaseiiniJaKaseinaatit"
                label={t(
                  "tuotantotiedot.juustojamuut.field.KaseiiniJaKaseinaatit"
                )}
              />
            </Box>
            <Divider />
            <Box mt={2}>
              <Typography variant="h3">
                {t("tuotantotiedot.juustojamuut.laktoosi")}
              </Typography>
              <KgField
                name="Laktoosi"
                label={t("tuotantotiedot.juustojamuut.field.Laktoosi")}
              />
            </Box>
            <Divider />
            <Box mt={2}>
              <Typography variant="h3">
                {t("tuotantotiedot.juustomyynti.title")}
              </Typography>
              <KgField
                name="KypsytetytJuustotSuoraanKulutukseen"
                label={t(
                  "tuotantotiedot.juustomyynti.field.KypsytetytJuustotJaSulatejuusto"
                )}
              />
              <KgField
                name="TuorejuustoSuoraanKulutukseen"
                label={t("tuotantotiedot.juustojamuut.field.Tuorejuustot")}
              />
              <KgField
                name="LeipäjuustoSuoraanKulutukseen"
                label={t("tuotantotiedot.juustojamuut.field.Leipajuusto")}
              />
            </Box>
            <Divider />
            <Box mt={2}>
              <Typography variant="h3">
                {t("tuotantotiedot.juustojamuut.varastot.title")}
              </Typography>
              <KgField
                name="KypsytetytJuustotVarasto"
                label={t(
                  "tuotantotiedot.juustojamuut.field.VarastointiKypsytetytJuustot"
                )}
              />
              <KgField
                name="VoiVarasto"
                label={t("tuotantotiedot.juustojamuut.field.VarastointiVoi")}
              />
            </Box>
            <FormActions>
              <PrevButton onClick={handlePreviousClick}></PrevButton>
              <NextButton
                disabled={loading}
                onClick={methods.handleSubmit(onSubmit)}
              ></NextButton>
            </FormActions>
          </Box>
        </Box>
      </FormProvider>
    </Fade>
  );
}
