import { useSelector } from "react-redux";
import { InquiryPeriod } from "../../store/inquiries/types";
import RootState from "../../store/types";

export default function usePeriods(key?: string): InquiryPeriod[] {
  const i = useSelector((state: RootState) => state.inquiries);

  if (!key) {
    return i;
  } else return (i || []).filter((i) => i.key === key);
}
