import { yupResolver } from "@hookform/resolvers/yup";
import { Fade, FormActions, NextButton } from "@lukefi-private/tikal-storybook";
import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { useData, useQuery } from "../common";
import { KgField } from "../common/Fields";
import { nullableNumber } from "../common/FormUtils";

type JuustojenValmistusForm = {
  JuustojenValmistusPehmea: number;
  JuustojenValmistusPuoliPehmea: number;
  JuustojenValmistusPuoliKova: number;
  JuustojenValmistusKova: number;
  JuustojenValmistusErittainKova: number;
  JuustojenValmistusTuorejuusto: number;
};

export default function JuustojenValmistus() {
  const query = useQuery();
  const history = useHistory();
  const { t } = useTranslation();

  const methods = useForm<JuustojenValmistusForm>({
    shouldFocusError: true,
    reValidateMode: "onBlur",
    resolver: yupResolver(
      yup.object().shape({
        JuustojenValmistusPehmea: nullableNumber,
        JuustojenValmistusPuoliPehmea: nullableNumber,
        JuustojenValmistusPuoliKova: nullableNumber,
        JuustojenValmistusKova: nullableNumber,
        JuustojenValmistusErittainKova: nullableNumber,
        JuustojenValmistusTuorejuusto: nullableNumber,
      })
    ),
  });

  const { update, loading } = useData(
    query.get("id"),
    methods.reset,
    methods.setError,
    methods.getValues
  );

  const onSubmit = async () => {
    try {
      // Push data to backend
      await update.execute();

      // Navigate to next page
      history.push(
        `/juustojen-valmistus/lomake/lisätiedot?id=${query.get("id")}`
      );
    } catch (e) {
      console.error(e);
    }
  };

  /** Submit data on component unmount */
  useEffect(() => {
    return () => {
      update.execute();
    };
  }, []);

  return (
    <Fade>
      <FormProvider {...methods}>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h2">
              {"1. " + t("juustojenvalmistus.title")}
            </Typography>
          </Box>

          <Box p={2} pt={0}>
            <KgField
              name="JuustojenValmistusPehmea"
              label={t("juustojenvalmistus.field.pehmeaJuusto")}
              showPreviousLabel
            />
            <KgField
              name="JuustojenValmistusPuoliPehmea"
              label={t("juustojenvalmistus.field.PuolipehmeaJuusto")}
            />
            <KgField
              name="JuustojenValmistusPuoliKova"
              label={t("juustojenvalmistus.field.puolikovaJuusto")}
            />
            <KgField
              name="JuustojenValmistusKova"
              label={t("juustojenvalmistus.field.kovaJuusto")}
            />
            <KgField
              name="JuustojenValmistusErittainKova"
              label={t("juustojenvalmistus.field.erittainKovaJuusto")}
            />
            <KgField
              name="JuustojenValmistusTuorejuusto"
              label={t("juustojenvalmistus.field.tuorejuusto")}
            />

            <FormActions>
              <span />
              <NextButton
                disabled={loading}
                onClick={methods.handleSubmit(onSubmit)}
              ></NextButton>
            </FormActions>
          </Box>
        </Box>
      </FormProvider>
    </Fade>
  );
}
