import * as yup from "yup";

export const nullableNumber = yup
  .number()
  .transform((currentValue, originalValue) => {
    return originalValue === "" ? null : currentValue;
  })
  .typeError("Syötä numeroarvo.")
  .nullable();

export const nullableNumberRequiredWith = (fields: string[]) =>
  nullableNumber.when(fields, {
    is: (val: any) => {
      return val !== null;
    },
    then: nullableNumber.required("Pakollinen tieto"),
  });

export const requiredNumber = yup
  .mixed()
  .transform((currentValue, originalValue) => {
    return originalValue === "" ? null : currentValue;
  })
  .required("Pakollinen tieto.")
  .typeError("Syötä numeroarvo.");
