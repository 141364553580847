export const SET_INQUIRIES = "SET_INQUIRIES";
export const CLEAR_INQUIRIES = "CLEAR_INQUIRIES";

interface SetInquiriesAction {
  type: typeof SET_INQUIRIES;
  payload: any;
}

interface ClearInquiriesAction {
  type: typeof CLEAR_INQUIRIES;
}

export type InquiryState = InquiryPeriod[];

export type InquiryActions = SetInquiriesAction | ClearInquiriesAction;

export type InquiryPeriod = {
  inquiry_id: string;
  period_id: string;
  name: string;
  key: string;
};
