import { NextButton, Title } from "@lukefi-private/tikal-storybook";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useHistory } from "react-router-dom";
import { Header } from "../common";
import Footer from "../common/Footer";
import useTokenState from "../common/useTokenState";

const useStyles = makeStyles(() => ({
  lukeLink: {
    color: "#fff",
  },
  authenticate: {
    display: "flex",
    alignItems: "flex-end",
  },
  flexContainer: {
    display: "flex",
    flexDirection: "column",
  },
  flexContent: {
    flex: "auto",
  },
  instructionsBox: {
    paddingTop: "10px",
  },
}));

export default function Kirjaudu() {
  const auth = useTokenState();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const handleLoginClick = () => {
    if (process.env.NODE_ENV === "development") {
      history.push("/aloitus");
    } else
      window.location.href = (process.env.REACT_APP_LOGIN_URL +
        `&lang=` +
        i18n.language) as string;
  };

  if (auth) return <Redirect to="aloitus" />;

  return (
    <>
      <Header loginScreen={true} />
      <Title>
        <Typography variant="h1" className={classes.instructionsBox}>
          {t("kirjaudu.title")}
        </Typography>
        <Box mt={2}>
          <Typography variant="body1">{t("kirjaudu.ohjeteksti1")}</Typography>
          <Typography variant="body1">
            {t("kirjaudu.ohjeteksti2.1")}
            <a className={classes.lukeLink} href="yrityskyselyt@luke.fi">
              yrityskyselyt@luke.fi
            </a>
            .&nbsp; {t("kirjaudu.ohjeteksti2.2")}
            <a
              className={classes.lukeLink}
              target="_blank"
              rel="noreferrer noopener"
              // eslint-disable-next-line max-len
              href={t("kirjaudu.ohjeteksti2.linkki")}
            >
              {t("kirjaudu.ohjeteksti2.linkkiTeksti")}
            </a>
            .
          </Typography>
          <Typography variant="body1">
            {t("kirjaudu.ohjeteksti3.1")}
            <a
              className={classes.lukeLink}
              target="_blank"
              rel="noreferrer noopener"
              // eslint-disable-next-line max-len
              href="https://stat.luke.fi/suomifi-tunnistus-ja-valtuudet-yritystiedonkeruissa_fi"
            >
              {t("kirjaudu.ohjeteksti3.linkkiTeksti")}
            </a>
          </Typography>
        </Box>
        <Box mt={3} className={classes.authenticate}>
          <NextButton
            color="secondary"
            size="large"
            text={t("kirjaudu.suomifi")}
            onClick={handleLoginClick}
          />
        </Box>
      </Title>
      <Footer sticky />
    </>
  );
}
