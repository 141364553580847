import jwt from "jsonwebtoken";
import useTokenState from "./useTokenState";

const devUserInfo = {
  gn: "Testi",
  n: "Testi Kehittäjä",
  c: "Luonnonvarakeskus",
};

type TokenUserInfo = {
  gn: string; // User given name
  n: string; // User full name
  c: string; // Company name
  upn?: string;
};

type TokenUserInfoPayload = {
  u: TokenUserInfo;
};

export default function useTokenUserInfo(): TokenUserInfo | null {
  if (process.env.NODE_ENV === "development") return devUserInfo;

  const token = useTokenState();

  if (token) {
    try {
      const payload = jwt.decode(token);
      const { u } = payload as TokenUserInfoPayload;

      return u;
    } catch (e) {
      console.error(e);
    }
  }

  return null;
}
