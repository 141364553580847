import { AuthActions, AuthState, DELETE_TOKEN, SET_TOKEN } from './types';

const initialState: AuthState = {
  token: undefined,
};

export default function authReducer(
  state = initialState,
  action: AuthActions
): AuthState {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };

    case DELETE_TOKEN:
      return {
        token: undefined,
      };

    default:
      return state;
  }
}
