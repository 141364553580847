import { TextField, TextFieldProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { PreviousValueContext } from "./PreviousValueContext";

const useStyles = makeStyles(() => ({
  hiddenLabel: {
    "& label": {
      color: "transparent !important",
    },
  },
}));

type PreviousValueProps = {
  id: string;
  label?: string;
  value: any;
  unit?: string;
  showLabel?: boolean;
};

export default function PreviousValue({
  id,
  showLabel,
  value,
  InputProps,
}: PreviousValueProps & TextFieldProps) {
  const { mode } = useContext(PreviousValueContext);
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <TextField
      variant="standard"
      className={showLabel ? "" : classes.hiddenLabel}
      id={id + `_previous`}
      label={
        mode == "year"
          ? t("common.edellinenvuosi")
          : t("common.edellinenkuukausi")
      }
      role="presentation"
      fullWidth
      disabled
      value={value}
      InputProps={InputProps}
      InputLabelProps={{ shrink: true }}
    ></TextField>
  );
}
