import { yupResolver } from "@hookform/resolvers/yup";
import {
  Checkbox,
  Fade,
  FormActions,
  NextButton,
} from "@lukefi-private/tikal-storybook";
import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { useData, useQuery } from "../common";
import { KgField } from "../common/Fields";
import { nullableNumber } from "../common/FormUtils";

type LuomumaitotuotteidenValmistusForm = {
  LuomumaitotuotteidenValmistusMaidot: number;
  LuomumaitotuotteidenValmistusKermat: number;
  LuomumaitotuotteidenValmistusPiimat: number;
  LuomumaitotuotteidenValmistusJogurtit: number;
  LuomumaitotuotteidenValmistusViilit: number;
  LuomumaitotuotteidenValmistusVoi: number;
  LuomumaitotuotteidenValmistusJuusto: number;
  LuomumaitotuotteidenValmistusMuut: number;
  EiLuomumaitotuotteidenValmistusta: boolean;
};

export default function LuomumaitotuotteidenValmistus() {
  const query = useQuery();
  const history = useHistory();
  const { t } = useTranslation();

  const methods = useForm<LuomumaitotuotteidenValmistusForm>({
    shouldFocusError: true,
    reValidateMode: "onBlur",
    resolver: yupResolver(
      yup.object().shape({
        LuomumaitotuotteidenValmistusMaidot: nullableNumber,
        LuomumaitotuotteidenValmistusKermat: nullableNumber,
        LuomumaitotuotteidenValmistusPiimat: nullableNumber,
        LuomumaitotuotteidenValmistusJogurtit: nullableNumber,
        LuomumaitotuotteidenValmistusViilit: nullableNumber,
        LuomumaitotuotteidenValmistusVoi: nullableNumber,
        LuomumaitotuotteidenValmistusJuusto: nullableNumber,
        LuomumaitotuotteidenValmistusMuut: nullableNumber,
        EiLuomumaitotuotteidenValmistusta: yup.boolean(),
      })
    ),
  });

  const noProductionChecked = methods.watch(
    "EiLuomumaitotuotteidenValmistusta"
  );

  const { update, loading } = useData(
    query.get("id"),
    methods.reset,
    methods.setError,
    methods.getValues
  );

  const onSubmit = async () => {
    try {
      // Push data to backend
      await update.execute();

      // Navigate to next page
      history.push(
        `/luomumaitotuotteiden-valmistus/lomake/lisätiedot?id=${query.get(
          "id"
        )}`
      );
    } catch (e) {
      console.error(e);
    }
  };

  /** Submit data on component unmount */
  useEffect(() => {
    return () => {
      update.execute();
    };
  }, []);

  return (
    <Fade>
      <FormProvider {...methods}>
        <Box>
          <Typography variant="h2">
            {"1. " + t("luomumaitotuotteidenvalmistus.title")}
          </Typography>
          <Box pt={2} pb={2}>
            <Checkbox
              label={t(
                "luomumaitotuotteidenvalmistus.field.EiLuomumaitotuotteidenValmistusta"
              )}
              name="EiLuomumaitotuotteidenValmistusta"
            />
          </Box>
          <Box p={2} pt={0}>
            {!noProductionChecked && (
              <>
                <KgField
                  name="LuomumaitotuotteidenValmistusMaidot"
                  label={t(
                    "luomumaitotuotteidenvalmistus.field.LuomumaitotuotteidenValmistusMaidot"
                  )}
                  showPreviousLabel
                />
                <KgField
                  name="LuomumaitotuotteidenValmistusKermat"
                  label={t(
                    "luomumaitotuotteidenvalmistus.field.LuomumaitotuotteidenValmistusKermat"
                  )}
                />
                <KgField
                  name="LuomumaitotuotteidenValmistusPiimat"
                  label={t(
                    "luomumaitotuotteidenvalmistus.field.LuomumaitotuotteidenValmistusPiimat"
                  )}
                />
                <KgField
                  name="LuomumaitotuotteidenValmistusJogurtit"
                  label={t(
                    "luomumaitotuotteidenvalmistus.field.LuomumaitotuotteidenValmistusJogurtit"
                  )}
                />
                <KgField
                  name="LuomumaitotuotteidenValmistusViilit"
                  label={t(
                    "luomumaitotuotteidenvalmistus.field.LuomumaitotuotteidenValmistusViilit"
                  )}
                />
                <KgField
                  name="LuomumaitotuotteidenValmistusVoi"
                  label={t(
                    "luomumaitotuotteidenvalmistus.field.LuomumaitotuotteidenValmistusVoi"
                  )}
                />
                <KgField
                  name="LuomumaitotuotteidenValmistusJuusto"
                  label={t(
                    "luomumaitotuotteidenvalmistus.field.LuomumaitotuotteidenValmistusJuusto"
                  )}
                />
                <KgField
                  name="LuomumaitotuotteidenValmistusMuut"
                  label={t(
                    "luomumaitotuotteidenvalmistus.field.LuomumaitotuotteidenValmistusMuut"
                  )}
                />
              </>
            )}
            <FormActions>
              <span />
              <NextButton
                disabled={loading}
                onClick={methods.handleSubmit(onSubmit)}
              ></NextButton>
            </FormActions>
          </Box>
        </Box>
      </FormProvider>
    </Fade>
  );
}
