import { ShrinkingTitle } from "@lukefi-private/tikal-storybook";
import {
  Divider,
  Fade,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Link,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { Footer, Header } from "../common";
import FormContent from "../common/FormContent";
import usePeriod from "../common/usePeriod";
import usePeriodTitle from "../common/usePeriodTitle";
import useQuery from "../common/useQuery";
import Valmis from "../common/Valmis";
import Jälkitili from "./Jälkitili";
import Lisätiedot from "./Lisätiedot";
import MuutVuosittaisetLisät from "./MuutVuosittaisetLisät";

const useStyles = makeStyles((theme) => ({
  nav: {
    color: "black",
  },
  navLink: {
    color: "inherit",
    textDecoration: "none",
  },
  divider: {
    marginRight: "-1px",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

export default function JälkitilitRouter() {
  const { t } = useTranslation();
  const location = useLocation();
  const query = useQuery();
  const { path } = useRouteMatch();
  const classes = useStyles();
  const [fade, setFade] = useState(false);
  const trTitle = usePeriodTitle();

  const { period } = usePeriod(query.get("id") as string);

  useEffect(() => {
    setTimeout(() => setFade(true), 500);
  }, []);

  return (
    <>
      <Header />
      <ShrinkingTitle
        title={t("jälkitilit.title") + " - " + trTitle(period?.name) || ""}
      ></ShrinkingTitle>
      <FormContent>
        <Fade in={fade}>
          <Grid container spacing={2}>
            <Grid item lg={2} md={3} xs={12}>
              <List className={classes.nav} component="nav">
                <Link
                  className={classes.navLink}
                  to={`${path}/jälkitili?id=${query.get("id")}`}
                >
                  <ListItem
                    dense
                    button
                    selected={location.pathname.endsWith("jälkitili")}
                  >
                    <ListItemText
                      primary={"1. " + t("jälkitilit.jälkitili.title")}
                    />
                  </ListItem>
                </Link>
                <Link
                  className={classes.navLink}
                  to={`${path}/muut-vuosittaiset-lisät?id=${query.get("id")}`}
                >
                  <ListItem
                    dense
                    button
                    selected={location.pathname.includes(
                      "muut-vuosittaiset-lisät"
                    )}
                  >
                    <ListItemText
                      primary={
                        "2. " + t("jälkitilit.muutvuosittaisetlisät.title")
                      }
                    />
                  </ListItem>
                </Link>
                <Link
                  className={classes.navLink}
                  to={`${path}/lisatiedot-ja-vahvistus?id=${query.get("id")}`}
                >
                  <ListItem
                    dense
                    button
                    selected={location.pathname.includes(
                      "lisatiedot-ja-vahvistus"
                    )}
                  >
                    <ListItemText
                      primary={"3. " + t("common.title.lisätiedot")}
                    />
                  </ListItem>
                </Link>
              </List>
            </Grid>

            <Divider
              orientation="vertical"
              flexItem
              className={classes.divider}
            />

            <Grid item lg={10} md={9} xs={12}>
              <Switch>
                <Route exact path={`${path}/jälkitili`} component={Jälkitili} />
                <Route
                  exact
                  path={`${path}/muut-vuosittaiset-lisät`}
                  component={MuutVuosittaisetLisät}
                />
                <Route
                  exact
                  path={`${path}/lisatiedot-ja-vahvistus`}
                  component={Lisätiedot}
                />
                <Route exact path={`${path}/valmis`} component={Valmis} />
              </Switch>
            </Grid>
          </Grid>
        </Fade>
      </FormContent>
      <Footer />
    </>
  );
}
