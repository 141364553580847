import { Action, Actions, Title } from "@lukefi-private/tikal-storybook";
import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Footer, Header } from "../common";
import usePeriodTitle from "../common/usePeriodTitle";
import useLuomumaitotuotteidenValmistusPeriods from "./useLuomumaitotuotteidenValmistusPeriods";

export default function Aloitus() {
  const actions = useLuomumaitotuotteidenValmistusPeriods();
  const { t } = useTranslation();
  const trTitle = usePeriodTitle();
  return (
    <>
      <Header />
      <Title>
        <Typography variant="h1">
          {t("luomumaitotuotteidenvalmistus.title")}
        </Typography>
        <Box mt={2}>
          <Typography variant="body1">{t("common.valitse.vuosi")}</Typography>
        </Box>
      </Title>

      <Actions>
        {actions.map((action) => (
          <Grid key={action.period_id} item xs={4} lg={3}>
            <Action
              title={trTitle(action.name)}
              href={`/luomumaitotuotteiden-valmistus/lomake?id=${action.period_id}`}
            />
          </Grid>
        ))}
      </Actions>
      <Footer sticky />
    </>
  );
}
