import React from "react";
import usePreviousValues from "./usePreviousValues";
import useQuery from "./useQuery";

export const PreviousValueContext = React.createContext({
  values: {},
  mode: null,
} as { values: any; mode: "month" | "year" | null });

export default function PreviousValueProvider({ mode, children }: any) {
  const query = useQuery();
  const previousValues = usePreviousValues(query.get("id") as string);

  return (
    <PreviousValueContext.Provider value={{ values: previousValues, mode }}>
      {children}
    </PreviousValueContext.Provider>
  );
}
