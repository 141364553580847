import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DELETE_TOKEN } from "../../store/auth/types";
import RootState from "../../store/types";

export default function useTokenState() {
  const dispatch = useDispatch();
  const token = useSelector((state: RootState) => state.auth.token);

  useEffect(() => {
    if (token) {
      const payload = JSON.parse(atob(token.split(".")[1]));
      const now = Date.now();

      /**
       * Token is expired => clear token and logout
       */
      if (now >= payload.exp * 1000) {
        dispatch({ type: DELETE_TOKEN });
      }
    }
  }, []);

  return token;
}
