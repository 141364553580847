import { yupResolver } from "@hookform/resolvers/yup";
import {
  Fade,
  FormActions,
  FormInstructions,
  NextButton,
} from "@lukefi-private/tikal-storybook";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { useData, useQuery } from "../common";
import {
  EuroField,
  KplField,
  LitraField,
  ProsenttiField,
  SentitPerLitraField,
  SenttiField,
} from "../common/Fields";
import {
  nullableNumber,
  nullableNumberRequiredWith,
  requiredNumber,
} from "../common/FormUtils";
import useTokenMetadata from "../common/useTokenMetadata";

const useStyles = makeStyles(() => ({
  formTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

type MaidonVastaanottotiedotForm = {
  MaidontuottajienLukumaara: number;
  LuomutuottajienLukumaara: number;
  MaidonVastaanottoYhteensa: number;
  LuomumaidonVastaanottoYhteensa: number;
  MaidonRasvapitoisuus: number;
  MaidonValkuaispitoisuus: number;
  KuukaudenAikanaVastaanotetustaMaidostaMaksettuYhteensa: number;
  EnnakkojalkitiliaMaksettu: number;
  VastaanotetustaMaidostaMaksettuEnnakkojalkitili: number;
  TuottajilleMaksettujaEnnakkojalkitilejaYhteensa: number;
};

const optionalSchema = yup.object().shape(
  {
    MaidontuottajienLukumaara: nullableNumber,
    LuomutuottajienLukumaara: nullableNumberRequiredWith([
      "LuomumaidonVastaanottoYhteensa",
    ]),
    LuomumaidonVastaanottoYhteensa: nullableNumberRequiredWith([
      "LuomutuottajienLukumaara",
    ]),
    MaidonVastaanottoYhteensa: nullableNumber,
    MaidonRasvapitoisuus: nullableNumber,
    MaidonValkuaispitoisuus: nullableNumber,
    KuukaudenAikanaVastaanotetustaMaidostaMaksettuYhteensa: nullableNumber,
    EnnakkojalkitiliaMaksettu: nullableNumber,
    VastaanotetustaMaidostaMaksettuEnnakkojalkitili: nullableNumber,
    TuottajilleMaksettujaEnnakkojalkitilejaYhteensa: nullableNumber,
  },
  [["LuomumaidonVastaanottoYhteensa", "LuomutuottajienLukumaara"]]
);

const requiredSchema = yup.object().shape(
  {
    MaidontuottajienLukumaara: requiredNumber,
    LuomutuottajienLukumaara: nullableNumberRequiredWith([
      "LuomumaidonVastaanottoYhteensa",
    ]),
    LuomumaidonVastaanottoYhteensa: nullableNumberRequiredWith([
      "LuomutuottajienLukumaara",
    ]),
    MaidonVastaanottoYhteensa: requiredNumber,
    MaidonRasvapitoisuus: requiredNumber,
    MaidonValkuaispitoisuus: requiredNumber,
    KuukaudenAikanaVastaanotetustaMaidostaMaksettuYhteensa: requiredNumber,
    EnnakkojalkitiliaMaksettu: nullableNumber,
    VastaanotetustaMaidostaMaksettuEnnakkojalkitili: nullableNumber,
    TuottajilleMaksettujaEnnakkojalkitilejaYhteensa: nullableNumber,
  },
  [["LuomumaidonVastaanottoYhteensa", "LuomutuottajienLukumaara"]]
);

const fieldNames = [
  "MaidontuottajienLukumaara",
  "MaidonVastaanottoYhteensa",
  "MaidonRasvapitoisuus",
  "MaidonValkuaispitoisuus",
  "KuukaudenAikanaVastaanotetustaMaidostaMaksettuYhteensa",
  "EnnakkojalkitiliaMaksettu",
  "VastaanotetustaMaidostaMaksettuEnnakkojalkitili",
  "TuottajilleMaksettujaEnnakkojalkitilejaYhteensa",
];

export default function MaidonVastaanottotiedot() {
  const [schema, setSchema] = useState(optionalSchema);
  const query = useQuery();
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();

  const methods = useForm<MaidonVastaanottotiedotForm>({
    shouldFocusError: true,
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
  });

  const luomumaitoOsanotto = useTokenMetadata("LUOMUMAIDON_VASTAANOTTO");

  const fields = methods.watch(fieldNames as any);

  const organicFields = methods.watch([
    "LuomumaidonVastaanottoYhteensa",
    "LuomutuottajienLukumaara",
  ]);

  /**
   * If non-organic fields have values, all are required => switch the schema to require all non-organic-fields.
   * Not clean, hopefully there is a better solution to come (in the validation libraries.)
   */
  useEffect(() => {
    const set = fields.filter(
      (value: number | string) => value !== null && value !== ""
    );
    if (set.length > 0) {
      setSchema(requiredSchema as any);
    } else {
      // Trigger validation on all fields if values are empty => inputs rerender and clear required errors
      setSchema(optionalSchema);

      if (
        Object.keys(methods.formState.errors).filter(
          (key) =>
            key !== "LuomutuottajienLukumaara" && // Note: removing either of these results in an infinite update loop
            key !== "LuomumaidonVastaanottoYhteensa"
        ).length
      ) {
        methods.trigger();
      }
    }
  }, [fields]);

  /**
   * Clear required error messages from organic fields if neither field has a value.
   */
  useEffect(() => {
    const set = organicFields.filter(
      (value: number | string) => value !== null && value !== ""
    );
    if (
      !set.length &&
      Object.keys(methods.formState.errors).filter(
        (key) =>
          key === "LuomutuottajienLukumaara" ||
          key === "LuomumaidonVastaanottoYhteensa"
      ).length
    ) {
      methods.trigger();
    }
  }, [organicFields]);

  const { update, loading } = useData(
    query.get("id"),
    methods.reset,
    methods.setError,
    methods.getValues
  );

  /** Submit data on component unmount */
  useEffect(() => {
    return () => {
      update.execute();
    };
  }, []);

  const onSubmit = async () => {
    try {
      // Push data to backend, without parameters because hook is aware of form state
      await update.execute();

      // Navigate to next page
      if (luomumaitoOsanotto) {
        history.push(
          `/maidontuotanto/lomake/luomumaidon-vastaanotto?id=${query.get("id")}`
        );
      } else {
        history.push(
          `/maidontuotanto/lomake/tuoretuotteiden-valmistus?id=${query.get(
            "id"
          )}`
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Fade>
      <FormProvider {...methods}>
        <Box>
          <Box className={classes.formTitle}>
            <Typography variant="h2">
              {"1. " + t("tuotantotiedot.vastaanotto.title")}
            </Typography>
            <Typography>
              <a
                target="_blank"
                rel="noreferrer noopener"
                // eslint-disable-next-line max-len
                href={t("kirjaudu.ohjeteksti2.linkki")}
              >
                {t("common.lomake.ohjelinkki.teksti")}
              </a>
            </Typography>
          </Box>
          <Box p={2} pt={0}>
            <FormInstructions>
              <Typography variant="body1">
                {t("tuotantotiedot.vastaanotto.ohjeteksti1.1")}
              </Typography>
            </FormInstructions>
            <KplField
              compareToPrevious
              name="MaidontuottajienLukumaara"
              label={t(
                "tuotantotiedot.vastaanotto.field.MaidontuottajienLukumaara"
              )}
              showPreviousLabel={true}
            />
            <KplField
              compareToPrevious
              indent
              name="LuomutuottajienLukumaara"
              label={t(
                "tuotantotiedot.vastaanotto.field.LuomutuottajienLukumaara"
              )}
            />
            <LitraField
              compareToPrevious
              name="MaidonVastaanottoYhteensa"
              label={t(
                "tuotantotiedot.vastaanotto.field.MaidonVastaanottoYhteensa"
              )}
            />
            <LitraField
              compareToPrevious
              indent
              name="LuomumaidonVastaanottoYhteensa"
              label={t(
                "tuotantotiedot.vastaanotto.field.LuomumaidonVastaanottoYhteensa"
              )}
            />
            <ProsenttiField
              compareToPrevious
              name="MaidonRasvapitoisuus"
              label={t("tuotantotiedot.vastaanotto.field.MaidonRasvapitoisuus")}
            />
            <ProsenttiField
              compareToPrevious
              name="MaidonValkuaispitoisuus"
              label={t(
                "tuotantotiedot.vastaanotto.field.MaidonValkuaispitoisuus"
              )}
            />
            <EuroField
              compareToPrevious
              name="KuukaudenAikanaVastaanotetustaMaidostaMaksettuYhteensa"
              label={t(
                "tuotantotiedot.vastaanotto.field.KuukaudenAikanaVastaanotetustaMaidostaMaksettuYhteensa"
              )}
            />
            <LitraField
              compareToPrevious
              name="EnnakkojalkitiliaMaksettu"
              label={t(
                "tuotantotiedot.vastaanotto.field.EnnakkojalkitiliaMaksettu"
              )}
            />
            <SentitPerLitraField
              compareToPrevious
              name="VastaanotetustaMaidostaMaksettuEnnakkojalkitili"
              label={t(
                "tuotantotiedot.vastaanotto.field.VastaanotetustaMaidostaMaksettuEnnakkojalkitili"
              )}
            />
            <EuroField
              compareToPrevious
              name="TuottajilleMaksettujaEnnakkojalkitilejaYhteensa"
              label={t(
                "tuotantotiedot.vastaanotto.field.TuottajilleMaksettujaEnnakkojalkitilejaYhteensa"
              )}
            />
            <FormActions>
              <span />
              <NextButton
                disabled={loading}
                onClick={methods.handleSubmit(onSubmit)}
              ></NextButton>
            </FormActions>
          </Box>
        </Box>
      </FormProvider>
    </Fade>
  );
}
