import { yupResolver } from "@hookform/resolvers/yup";
import {
  Fade,
  FormActions,
  NextButton,
  PrevButton,
} from "@lukefi-private/tikal-storybook";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { useData, useQuery } from "../common";
import { EuroField, SentitPerLitraField } from "../common/Fields";
import { nullableNumberRequiredWith } from "../common/FormUtils";

const useStyles = makeStyles(() => ({
  formTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

type LuomumaidonVastaanottotiedotForm = {
  LuomumaidostaMaksettuYhteensa: number;
  LuomuMaidonArvioituHinta: number;
};

export default function LuomumaidonVastaanottotiedot() {
  const query = useQuery();
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();

  const methods = useForm<LuomumaidonVastaanottotiedotForm>({
    shouldFocusError: true,
    reValidateMode: "onBlur",
    resolver: yupResolver(
      yup.object().shape(
        {
          LuomumaidostaMaksettuYhteensa: nullableNumberRequiredWith([
            "LuomuMaidonArvioituHinta",
          ]),
          LuomuMaidonArvioituHinta: nullableNumberRequiredWith([
            "LuomumaidostaMaksettuYhteensa",
          ]),
        },
        [["LuomumaidostaMaksettuYhteensa", "LuomuMaidonArvioituHinta"]]
      )
    ),
  });

  const { update, loading } = useData(
    query.get("id"),
    methods.reset,
    methods.setError,
    methods.getValues
  );

  const onSubmit = async () => {
    try {
      // Push data to backend, without parameters because hook is aware of form state
      await update.execute();

      // Navigate to next page
      history.push(
        `/maidontuotanto/lomake/tuoretuotteiden-valmistus?id=${query.get("id")}`
      );
    } catch (e) {
      console.error(e);
    }
  };

  /** Submit data on component unmount */
  useEffect(() => {
    return () => {
      update.execute();
    };
  }, []);

  const handlePreviousClick = () =>
    history.push(
      `/maidontuotanto/lomake/maidon-vastaanotto?id=${query.get("id")}`
    );

  return (
    <Fade>
      <FormProvider {...methods}>
        <Box>
          <Box className={classes.formTitle}>
            <Typography variant="h2">
              {"1.1. " + t("tuotantotiedot.luomumaidontuottajahinta.title")}
            </Typography>
            <Typography>
              <a
                target="_blank"
                rel="noreferrer noopener"
                // eslint-disable-next-line max-len
                href={t("kirjaudu.ohjeteksti2.linkki")}
              >
                {t("common.lomake.ohjelinkki.teksti")}
              </a>
            </Typography>
          </Box>
          <Box p={2}>
            <EuroField
              name="LuomumaidostaMaksettuYhteensa"
              label={t(
                "tuotantotiedot.luomumaidontuottajahinta.field.LuomumaidostaMaksettuYhteensa"
              )}
              showPreviousLabel
            />

            <Box mt={2}>
              <Typography variant="h3">
                {t(
                  "tuotantotiedot.luomumaidontuottajahinta.arvioKuluvanKuuKaudenHinnasta"
                )}
              </Typography>
              <SentitPerLitraField
                name="LuomuMaidonArvioituHinta"
                label={t(
                  "tuotantotiedot.luomumaidontuottajahinta.field.LuomuMaidonArvioituHinta"
                )}
              />
            </Box>
            <FormActions>
              <PrevButton onClick={handlePreviousClick}></PrevButton>
              <NextButton
                disabled={loading}
                onClick={methods.handleSubmit(onSubmit)}
              ></NextButton>
            </FormActions>
          </Box>
        </Box>
      </FormProvider>
    </Fade>
  );
}
