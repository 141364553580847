import { yupResolver } from "@hookform/resolvers/yup";
import {
  Fade,
  FormActions,
  FormInstructions,
  NextButton,
  PrevButton,
} from "@lukefi-private/tikal-storybook";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { useData, useQuery } from "../common";
import { KgField } from "../common/Fields";
import { nullableNumber } from "../common/FormUtils";

const useStyles = makeStyles(() => ({
  formTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

type MaidonRaakaaineenValmistustiedotForm = {
  RaakaaineRasvatonMaito: number;
  RaakaaineMaito: number;
  RaakaaineKerma: number;
};

export default function MaitoRaakaaineenValmistustiedot() {
  const query = useQuery();
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();

  const methods = useForm<MaidonRaakaaineenValmistustiedotForm>({
    shouldFocusError: true,
    reValidateMode: "onBlur",
    resolver: yupResolver(
      yup.object().shape({
        RaakaaineRasvatonMaito: nullableNumber,
        RaakaaineMaito: nullableNumber,
        RaakaaineKerma: nullableNumber,
      })
    ),
  });

  const { update, loading } = useData(
    query.get("id"),
    methods.reset,
    methods.setError,
    methods.getValues
  );

  const onSubmit = async () => {
    try {
      // Push data to backend
      await update.execute();

      // Navigate to next page
      history.push(
        `/maidontuotanto/lomake/lisatiedot-ja-vahvistus?id=${query.get("id")}`
      );
    } catch (e) {
      console.error(e);
    }
  };

  /** Submit data on component unmount */
  useEffect(() => {
    return () => {
      update.execute();
    };
  }, []);

  const handlePreviousClick = () =>
    history.push(
      `/maidontuotanto/lomake/juusto-ja-muut-maitotuotteet-valmistus-varastot?id=${query.get(
        "id"
      )}`
    );

  return (
    <Fade>
      <FormProvider {...methods}>
        <Box>
          <Box className={classes.formTitle}>
            <Typography variant="h2">
              {"4. " + t("tuotantotiedot.maitoraakaaineenvalmistus.title")}
            </Typography>
            <Typography>
              <a
                target="_blank"
                rel="noreferrer noopener"
                // eslint-disable-next-line max-len
                href={t("kirjaudu.ohjeteksti2.linkki")}
              >
                {t("common.lomake.ohjelinkki.teksti")}
              </a>
            </Typography>
          </Box>

          <Box p={2} pt={0}>
            <FormInstructions>
              <Typography variant="body1">
                {t("tuotantotiedot.maitoraakaaineenvalmistus.ohjeteksti")}
              </Typography>
            </FormInstructions>
            <KgField
              name="RaakaaineRasvatonMaito"
              label={t(
                "tuotantotiedot.maitoraakaaineenvalmistus.field.RaakaaineRasvatonMaito"
              )}
              showPreviousLabel={true}
            />
            <KgField
              name="RaakaaineMaito"
              label={t(
                "tuotantotiedot.maitoraakaaineenvalmistus.field.RaakaaineMaito"
              )}
            />
            <KgField
              name="RaakaaineKerma"
              label={t(
                "tuotantotiedot.maitoraakaaineenvalmistus.field.RaakaaineKerma"
              )}
            />
            <FormActions>
              <PrevButton onClick={handlePreviousClick}></PrevButton>
              <NextButton
                disabled={loading}
                onClick={methods.handleSubmit(onSubmit)}
              ></NextButton>
            </FormActions>
          </Box>
        </Box>
      </FormProvider>
    </Fade>
  );
}
