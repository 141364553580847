import { LanguageSelector } from "@lukefi-private/tikal-storybook";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import Flag from "react-flagkit";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import logo from "../../assets/logo_fi.png";
import useLogout from "./useLogout";
import useTokenUser from "./useTokenUserInfo";
const useStyles = makeStyles((theme) => ({
  logout: {
    textAlign: "right",
  },
  link: {
    color: "#0033a0",
    textDecoration: "underline",
    cursor: "pointer",
  },
  items: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  item: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
}));

type HeaderProps = {
  loginScreen?: boolean; // For design demo purposes only
};

export default function Header({ loginScreen }: HeaderProps) {
  const { t, i18n } = useTranslation();

  const user = useTokenUser();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleLogout = useLogout();

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => setAnchorEl(null);

  const handleLanguageChange = (lng: string) => {
    setAnchorEl(null);
    setTimeout(() => {
      i18n.changeLanguage(lng);
    }, 100);
  };

  return (
    <Box pl={8} pr={8} pt={4} pb={4}>
      <Grid container justifyContent="space-between">
        <Grid className={classes.item} item sm={4} xs={12}>
          <RouterLink to="/">
            <div>
              <img src={logo} height={55} alt="Logo" />
            </div>
          </RouterLink>
        </Grid>

        <Grid
          className={`${classes.item} ${classes.logout}`}
          item
          sm={4}
          xs={12}
        >
          {!loginScreen && (
            <div className={classes.items}>
              <Typography variant="subtitle2">
                {user?.n}, <strong>{user?.c}</strong>
              </Typography>
              <IconButton aria-label="Avaa valikko" onClick={handleMenuClick}>
                <ExpandMoreIcon />
              </IconButton>
              <Menu
                open={open}
                onClose={handleMenuClose}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              >
                {i18n.language !== "fi" && (
                  <MenuItem onClick={() => handleLanguageChange("fi")}>
                    <Flag style={{ marginRight: "10px" }} country="FI" />
                    {t("common.fi")}
                  </MenuItem>
                )}
                {i18n.language !== "sv" && (
                  <MenuItem onClick={() => handleLanguageChange("sv")}>
                    <Flag style={{ marginRight: "10px" }} country="SE" />
                    {t("common.se")}
                  </MenuItem>
                )}
                <MenuItem onClick={handleLogout}>{t("common.logout")}</MenuItem>
              </Menu>
            </div>
          )}
          {loginScreen && <LanguageSelector />}
        </Grid>
      </Grid>
    </Box>
  );
}
