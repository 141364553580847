import { CLEAR_INQUIRIES, InquiryActions, SET_INQUIRIES } from "./types";

export function setInquiries(inquiries: any): InquiryActions {
  return {
    type: SET_INQUIRIES,
    payload: inquiries,
  };
}

export function clearInquiries(): InquiryActions {
  return {
    type: CLEAR_INQUIRIES,
  };
}
