import { Title } from "@lukefi-private/tikal-storybook";
import { Box, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { Footer, Header } from "../common";

export default function Accessibility() {
  return (
    <>
      <Header loginScreen={true} />
      <Title>
        <Typography variant="h1">Saavutettavuusseloste</Typography>
      </Title>
      <Box p={8} pt={4}>
        <Typography variant="body1">
          Tässä saavutettavuusselosteessa kerrotaan, miten Luonnonvarakeskuksen
          Kirnu-tiedonkeruusovelluksessa noudatetaan lakia digitaalisten
          palvelujen tarjoamisesta ja miten voit antaa meille palautetta
          saavutettavuusongelmista.
        </Typography>
        <Typography variant="body1">
          Tästä tiedonkeruusovelluksesta vastaa Luonnonvarakeskus (Luke).
          Haluamme, että mahdollisimman moni käyttäjä pystyy käyttämään
          digitaalisia palvelujamme. Otamme saavutettavuuden huomioon
          digitaalisten palvelujen kehityksessä.
        </Typography>
        <Typography variant="h2">
          Miten saavutettava tiedonkeruusovellus on?
        </Typography>
        <Typography variant="body1">
          Tämä tiedonkeruusovellus täyttää lain vaatimat A- ja AA-tason
          saavutettavuuskriteerit (WCAG-kriteeristö 2.1) kokonaisuudessaan.
        </Typography>
        <Typography variant="h2">
          Anna meille palautetta sovelluksen saavutettavuudesta
        </Typography>
        <Typography variant="body1">
          Jos huomaat tiedonkeruusovelluksessa saavutettavuusongelmia, niin
          kerrothan niistä meille. Kaikki palaute on meille arvokasta. Lähetä
          saavutettavuutta koskeva palautteesi osoitteeseen{" "}
          <a href="mailto:tilastokyselyt@luke.fi">tilastokyselyt@luke.fi</a>.
          Vastaamme palautteeseesi 14 päivän sisällä.
        </Typography>
        <Typography variant="body1">
          Voit olla yhteydessä osoitteeseen{" "}
          <a href="mailto:tilastokyselyt@luke.fi">tilastokyselyt@luke.fi</a>{" "}
          myös sovelluksen käyttöongelmiin liittyvissä kysymyksissä.
          Kirnu-tilastokyselyn tiedonkeruun aikana Luonnonvarakeskus auttaa
          tiedonluovuttajia antamaan tiedot tarvittaessa vaihtoehtoisella
          tavalla.
        </Typography>
        <Typography variant="h2">Saavutettavuuden valvonta</Typography>
        <Typography variant="body1">
          Jos et ole tyytyväinen antamaamme vastaukseen, voit tehdä ilmoituksen{" "}
          <a
            rel="noreferrer"
            href="https://www.saavutettavuusvaatimukset.fi/oikeutesi/"
            target="_blank"
          >
            Etelä-Suomen aluehallintovirastoon
          </a>
          . Sivustolla kerrotaan tarkasti, miten ilmoituksen voi tehdä, ja miten
          asia käsitellään.
        </Typography>{" "}
        <Typography variant="body1">
          Etelä-Suomen aluehallintovirasto
          <br /> Saavutettavuuden valvonnan yksikkö
          <br />{" "}
          <a
            href="http://www.saavutettavuusvaatimukset.fi"
            title="www.saavutettavuusvaatimukset.fi"
          >
            www.saavutettavuusvaatimukset.fi
          </a>
          <br />{" "}
          <a href="mailto:saavutettavuus@avi.fi">saavutettavuus@avi.fi</a>
          <br />/ puhelinnumero vaihde 0295 016&nbsp;000
        </Typography>{" "}
        <Typography variant="body1">
          <a
            rel="noreferrer"
            href="https://www.finlex.fi/fi/laki/alkup/2019/20190306"
            target="_blank"
          >
            Laki digitaalisten palvelujen tarjoamisesta (306/2019){" "}
          </a>
          <br />{" "}
          <a
            rel="noreferrer"
            href="https://www.saavutettavuusvaatimukset.fi"
            target="_blank"
          >
            Saavutettavuusvaatimukset-verkkosivusto
          </a>
        </Typography>
        <Typography variant="body1">
          Tämä seloste on laadittu 18.01.2022.
        </Typography>
        <Typography variant="body1">
          <Link to="/">Palaa etusivulle</Link>
        </Typography>
      </Box>
      <Footer />
    </>
  );
}
