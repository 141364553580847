import { Footer as TikalFooter } from "@lukefi-private/tikal-storybook";
import React from "react";

export default function Footer({ sticky }: { sticky?: boolean }) {
  return (
    <TikalFooter
      sticky={sticky}
      firstColumnLinks={[
        {
          text: "footer.saavutettavuus",
          href: "/saavutettavuus",
        },
      ]}
      secondColumnLinks={[
        {
          text: "footer.maitotilastot",
          href: "https://stat.luke.fi/maito-ja-maitotuotetilasto",
        },
        {
          text: "footer.tuottajahinnat",
          href: "https://stat.luke.fi/maataloustuotteiden-tuottajahinnat",
        },
      ]}
    ></TikalFooter>
  );
}
