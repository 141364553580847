import {
  CLEAR_INQUIRIES,
  InquiryActions,
  InquiryState,
  SET_INQUIRIES,
} from "./types";

const initialState: InquiryState = [];
export default function inquiryReducer(
  state = initialState,
  action: InquiryActions
): InquiryState {
  switch (action.type) {
    case SET_INQUIRIES:
      return action.payload;

    case CLEAR_INQUIRIES:
      return [];

    default:
      return state;
  }
}
