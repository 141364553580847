import axios from "axios";
import { useAsync } from "react-async-hook";

export default function useFinishData(id: string | null) {
  const endpoint =
    (process.env.REACT_APP_DATA_API_URL + "/data/finish" ||
      "localhost:3000/data/finish") + `?id=${id}`;
  /**
   * Update data to backend, hook returns this function
   */
  const finish = useAsync(
    async () => {
      try {
        // Remove empty values from payload before sending it
        await axios.post(endpoint, {});
        return true;
      } catch (e) {
        return true;
      }
    },
    [],
    { executeOnMount: false }
  );

  // Loading true get or put requests are active
  // Error object is set from put requests only
  return {
    finish,
    loading: finish.loading || finish.loading,
  };
}
