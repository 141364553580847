import { yupResolver } from "@hookform/resolvers/yup";
import {
  Fade,
  FormActions,
  FormInstructions,
  NextButton,
  PrevButton,
  TextField,
} from "@lukefi-private/tikal-storybook";
import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { useData, useFinishData, useQuery } from "../common";

type LisätiedotForm = {
  MaidontuotantoLisätiedot: number;
};

export default function Lisätiedot() {
  const query = useQuery();
  const history = useHistory();
  const { t } = useTranslation();

  const methods = useForm<LisätiedotForm>({
    shouldFocusError: true,
    reValidateMode: "onBlur",
    resolver: yupResolver(
      yup.object().shape({
        MaidontuotantoLisätiedot: yup.string().nullable(),
      })
    ),
  });

  const { finish } = useFinishData(query.get("id"));

  const { update, loading } = useData(
    query.get("id"),
    methods.reset,
    methods.setError,
    methods.getValues
  );

  const onSubmit = async () => {
    try {
      // Push data to backend
      await update.execute();
      await finish.execute();
      history.push(`/maidontuotanto/lomake/valmis?id=${query.get("id")}`);
    } catch (e) {
      console.error(e);
    }
  };

  /** Submit data on component unmount */
  useEffect(() => {
    return () => {
      update.execute();
    };
  }, []);

  const handlePreviousClick = () =>
    history.push(
      `/maidontuotanto/lomake/maitoraakaaineen-valmistus?id=${query.get("id")}`
    );

  return (
    <Fade>
      <FormProvider {...methods}>
        <Box>
          <Typography variant="h2">
            {"5. " + t("common.title.lisätiedot")}
          </Typography>

          <Box p={2} pt={0}>
            <FormInstructions>
              <Typography variant="body1">
                {t("common.lisatiedot.ohjeteksti")}{" "}
                <a href="mailto:yrityskyselyt@luke.fi">yrityskyselyt@luke.fi</a>
                .
              </Typography>
            </FormInstructions>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <TextField
                  multiline
                  name="MaidontuotantoLisätiedot"
                  fullWidth
                  label={t("common.field.lisätiedot")}
                ></TextField>
              </Grid>
            </Grid>
            <FormActions>
              <PrevButton onClick={handlePreviousClick}></PrevButton>
              <NextButton
                text={t("common.lähetä")}
                disabled={loading}
                onClick={methods.handleSubmit(onSubmit)}
              ></NextButton>
            </FormActions>
          </Box>
        </Box>
      </FormProvider>
    </Fade>
  );
}
