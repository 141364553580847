import { ShrinkingTitle } from "@lukefi-private/tikal-storybook";
import {
  Divider,
  Fade,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Link,
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { Footer, Header } from "../common";
import FormContent from "../common/FormContent";
import PreviousValueProvider from "../common/PreviousValueContext";
import usePeriod from "../common/usePeriod";
import usePeriodTitle from "../common/usePeriodTitle";
import useQuery from "../common/useQuery";
import Valmis from "../common/Valmis";
import JuustojenValmistus from "./JuustojenValmistus";
import Lisätiedot from "./Lisätiedot";

const useStyles = makeStyles((theme) => ({
  nav: {
    color: "black",
  },
  navLink: {
    color: "inherit",
    textDecoration: "none",
  },
  divider: {
    marginRight: "-1px",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

export default function JuustojenValmistusRouter() {
  const location = useLocation();
  const query = useQuery();
  const { path } = useRouteMatch();
  const classes = useStyles();
  const [fade, setFade] = useState(false);
  const { t } = useTranslation();
  const { period } = usePeriod(query.get("id") as string);
  const trTitle = usePeriodTitle();

  useEffect(() => {
    setTimeout(() => setFade(true), 500);
  }, []);

  return (
    <>
      <Header />
      <ShrinkingTitle
        title={
          t("juustojenvalmistus.title") + " - " + trTitle(period?.name) || ""
        }
      ></ShrinkingTitle>
      <FormContent>
        <Fade in={fade}>
          <Grid container spacing={2}>
            <Grid item lg={2} md={3} xs={12}>
              <List className={classes.nav} component="nav">
                <Link
                  className={classes.navLink}
                  to={`${path}/juustojen-valmistus?id=${query.get("id")}`}
                >
                  <ListItem
                    dense
                    button
                    selected={location.pathname.endsWith("juustojen-valmistus")}
                  >
                    <ListItemText
                      primary={"1. " + t("juustojenvalmistus.title")}
                    />
                  </ListItem>
                </Link>
                <Link
                  className={classes.navLink}
                  to={`${path}/lisätiedot?id=${query.get("id")}`}
                >
                  <ListItem
                    dense
                    button
                    selected={location.pathname.endsWith("lisätiedot")}
                  >
                    <ListItemText
                      primary={"2. " + t("common.title.lisätiedot")}
                    />
                  </ListItem>
                </Link>
              </List>
            </Grid>

            <Divider
              orientation="vertical"
              flexItem
              className={classes.divider}
            />
            <PreviousValueProvider mode="year">
              <Grid item lg={10} md={9} xs={12}>
                <Switch>
                  <Route
                    path={`${path}/juustojen-valmistus`}
                    component={JuustojenValmistus}
                  />

                  <Route path={`${path}/lisätiedot`} component={Lisätiedot} />
                  <Route exact path={`${path}/valmis`} component={Valmis} />
                  <Redirect
                    exact
                    from={`${path}/`}
                    to={`${path}/juustojen-valmistus?id=${query.get("id")}`}
                  />
                </Switch>
              </Grid>
            </PreviousValueProvider>
          </Grid>
        </Fade>
      </FormContent>
      <Footer />
    </>
  );
}
