{
  "kirjaudu.title": "Vällkommen till Naturresursinstitutets Kirnu- applikation!",
  "kirjaudu.ohjeteksti1": "Välkommen att svara till Naturresursinstitutets elektroniska datainsamling. Uppgifterna du lämnar behandlas konfidentiellt och de används endast för statistiska ändamål.",
  "kirjaudu.ohjeteksti2.1": "För mer information om förfrågan, vänligen ta kontakt per e-post på ",
  "kirjaudu.ohjeteksti2.2": "Kontaktpersonernas telefonnummer finns på Naturresursinstitutets ",
  "kirjaudu.ohjeteksti2.linkki": "https://www.luke.fi/sv/anvisning-statistikforfragan-om-produktion-av-mjolk-och-mjolkprodukter-och-producentpriser-for-mjolk",
  "kirjaudu.ohjeteksti2.linkkiTeksti": "anvisning",
  "kirjaudu.ohjeteksti3.1": "Anvisningar för identifikation och företagsfullmakt finns på Lukes ",
  "kirjaudu.ohjeteksti3.linkkiTeksti": "hemsida.",
  "kirjaudu.suomifi": "Logga in med Suomi.fi-identifikation",
  "kirjaudu.tunnistautuminenEpaonnistui.title": "Identifikation på applikationen avbrytas",
  "kirjaudu.tunnistautuminenEpaonnistui.return": "Tillbaks till början",
  "common.logout": "Logga ut",
  "common.hello": "Hej",
  "common.lähetä": "Skicka",
  "common.fi": "På finska",
  "common.se": "På svenska",
  "common.kpl": "st",
  "common.snt": "c",
  "common.sntperlitra": "c/l",
  "common.edellinen": "Förra",
  "common.seuraava": "Nästa",
  "common.lisatiedot.ohjeteksti": "Du kan ange tilläggsuppgifter gällande uppgifterna för denna tidpunkt, t.ex. en förklaring till avvikande volym- eller prisuppgifter. Detta underlättar kontrollen av uppgifterna vid Statistiktjänsten. Du kan även anmäla om förändrade kontaktuppgifter på den här punkten. Ifall det skett förändringar i de tidigare inlämnade uppgifterna ber vi dig skicka e-post om detta till adressen",
  "common.lisatiedot.ohjeteksti.lyhyt": "Du kan ange tilläggsuppgifter gällande uppgifterna för denna tidpunkt, t.ex. en förklaring till avvikande volym- eller prisuppgifter. Detta underlättar kontrollen av uppgifterna vid Statistiktjänsten. Du kan även anmäla om förändrade kontaktuppgifter på den här punkten. Ifall det skett förändringar i de tidigare inlämnade uppgifterna ber vi dig skicka e-post om detta till adressen",
  "common.valitse.lomake": "Börja med att välja formulär",
  "common.valitse.vuosi": "Välj året",
  "common.valitse.kuukausi": "Välj månaden",
  "common.field.lisätiedot": "Tilläggsinformation",
  "common.title.lisätiedot": "Tilläggsinformation och bekräftelse",
  "common.edellinenkuukausi": "Föregående månad",
  "common.edellinenvuosi": "Föregående år",
  "common.tammikuu": "januari",
  "common.helmikuu": "februari",
  "common.maaliskuu": "mars",
  "common.huhtikuu": "april",
  "common.toukokuu": "maj",
  "common.kesäkuu": "juni",
  "common.heinäkuu": "juli",
  "common.elokuu": "augusti",
  "common.syyskuu": "september",
  "common.lokakuu": "oktober",
  "common.marraskuu": "november",
  "common.joulukuu": "december",
  "common.vuosi": "år",
  "common.lomake.ohjelinkki.teksti": "Anvisningar",
  "common.lomake.ohjelinkki": "länk",
  "tuotantotiedot.title": "Mjölkproduktion, producentpris och tillverkning av mjölkprodukter",
  "tuotantotiedot.description": "Ge ditt företags månatliga uppgifter",
  "tuotantotiedot.vastaanotto.title": "Mängden mottagen mjölk och producentpris",
  "tuotantotiedot.PoikkeavaArvo": "Högt förandring (över 20 %) jämfört med det föregående värde",
  "tuotantotiedot.tuoretuotteidenvalmistus.title": "Tillverkning av färskprodukter",
  "tuotantotiedot.tuoretuotteidenvalmistus.ohjeteksti": "Under tillverkning av mjölkprodukter anges den mängd mjölkprodukter som \r\ntillverkats i företagets egen produktionsanläggning och de produkter företaget låtit \r\ntillverka i andra företag. Under mängden tillverkade mjölkprodukter anges endast \r\ntillverkningen av produkter som kan saluföras. Ekologisk mjölkprodukter inkluderas i kategorier.",
  "tuotantotiedot.tuoretuotteidenvalmistus.ohjeteksti2": "Som mjölk- och vasslebaserade drycker redovisas produkter som innehåller minst 50 \r\nprocent mjölkprodukter.",
  "tuotantotiedot.tuoretuotteidenvalmistus.ohjeteksti3": "Under övriga färskprodukter anmälas t.ex. glass, olika mjölkbaserade såser och desserter.",
  "tuotantotiedot.tuoretuotteidenvalmistus.maito": "Mjölk",
  "tuotantotiedot.tuoretuotteidenvalmistus.kermat": "Grädde",
  "tuotantotiedot.tuoretuotteidenvalmistus.viilit": "Fil",
  "tuotantotiedot.tuoretuotteidenvalmistus.jogurtit": "Yoghurt",
  "tuotantotiedot.tuoretuotteidenvalmistus.rahka": "Kvark",
  "tuotantotiedot.tuoretuotteidenvalmistus.vanukkaat": "Puddingar",
  "tuotantotiedot.tuoretuotteidenvalmistus.muuttuoretuotteet": "Övriga färskprodukter",
  "tuotantotiedot.tuoretuotteidenvalmistus.maitojaherapohjaisetjuomat": "Mjölk- och vasslebaserade drycker",
  "tuotantotiedot.tuoretuotteidenvalmistus.hapatetutkermavalmisteet": "Syrade gräddeprodukter",
  "tuotantotiedot.juustojamuut.title": "Tillverkning av andra mejeriprodukter,  inhemsk försäljning och lager",
  "tuotantotiedot.juustojamuut.ohjeteksti": "Under tillverkning av mjölkprodukter anges den mängd mjölkprodukter som \r\ntillverkats i företagets egen produktionsanläggning och de produkter företaget låtit \r\ntillverka i andra företag. Under mängden tillverkade mjölkprodukter anges endast \r\ntillverkningen av produkter som kan saluföras. Ekologisk mjölkprodukter inkluderas i kategorier.",
  "tuotantotiedot.vastaanotto.ohjeteksti1.1": "Anmäla uppgifter om mottaget och betalat mjölk. Alla priser, fett- och proteinhalt anges med en noggrannhet på två decimaler, övriga uppgifter som ett heltal. ",
  "tuotantotiedot.vastaanotto.field.MaidontuottajienLukumaara": "Antal av mjölkproducenter",
  "tuotantotiedot.vastaanotto.field.LuomutuottajienLukumaara": "... varav ekoproducenter",
  "tuotantotiedot.vastaanotto.field.MaidonVastaanottoYhteensa": "Mottagen mjölk",
  "tuotantotiedot.vastaanotto.field.LuomumaidonVastaanottoYhteensa": "... varav ekomjölk",
  "tuotantotiedot.vastaanotto.field.MaidonRasvapitoisuus": "Mjölkens fettprocent",
  "tuotantotiedot.vastaanotto.field.MaidonValkuaispitoisuus": "Mjölkens proteinprocent",
  "tuotantotiedot.vastaanotto.field.ILuokanNormimaidonHinta": "Producentpris på normmjölk, klass I",
  "tuotantotiedot.vastaanotto.field.KuukaudenAikanaVastaanotetustaMaidostaMaksettuYhteensa": "Penningsumma som betalats för mjölken som mottagits under månaden (€ totalt)",
  "tuotantotiedot.vastaanotto.field.EnnakkojalkitiliaMaksettu": "Mjölkmängden, som förskott av efteravgift berör",
  "tuotantotiedot.vastaanotto.field.VastaanotetustaMaidostaMaksettuEnnakkojalkitili": "Betalt förskott av efteravgift, c/l",
  "tuotantotiedot.vastaanotto.field.TuottajilleMaksettujaEnnakkojalkitilejaYhteensa": "Betalt förskott av efteravgift, euro",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.RasvatonMaitoPastoroitu1ProsJaAlle": "Fettfri mjölk, pastöriserad 1 % och under",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.KevytmaitoPastoroituYli1Pros_3Pros": "Lättmjölk, pastöriserad över 1 % - 3 %",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.TaysmaitoPastoroituYli3Pros": "Helmjölk, pastöriserad över 3 %",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.RasvatonMaitoUHT1ProsJaAlle": "Fettfri mjölk, UHT 1 % och under",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.KevytmaitoUHTYli1Pros_3Pros": "Lättmjölk, UHT over 1 % - 3 %",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.TaysmaitoUHTYli3Pros": "Helmjölk, UHT över 3 %",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.RasvatonMaitojuoma1ProsJaAlle": "Fettfri mjölkdryck 1 % och under",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.KevytmaitojuomaYli1Pros_3Pros": "Lättmjölkdryck över 1 % - 3 %",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.TaysmaitojuomaYli3Pros": "Helmjölkdryck över 3 %",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.Piimat": "Surmjölk",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.KermaYli21Pros": "Grädde, mjölkfetthalt över 21 %",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.Kermat21ProsJaAlle": "Grädde, mjölkfetthalt 21 % och under",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.Kermaviili": "Gräddfil",
  "tuotantotiedot.juustojamuut.juustotlehmanmaidosta": "Ostar av komjölk",
  "tuotantotiedot.juustojamuut.juustotvuohenmaidosta": "Ostar av getmjölk",
  "tuotantotiedot.juustojamuut.voijarasvaseokset": "Smör och fettblandningar",
  "tuotantotiedot.juustojamuut.kaseiinijakaseinaatit": "Kasein och kaseinater",
  "tuotantotiedot.juustojamuut.laktoosi": "Laktos",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.Ranskankerma": "Crème Fraîche",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.Smetana": "Smetana",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.ViiliEiSisLisaaineita": "Fil, utan tillsatsämnen",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.ViiliSisLisaaineita": "Fil, innehåller tillsatsämnen",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.JogurttiEiSisLisaaineita": "Yoghurt, utan tillsatsämnen",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.JogurttiSisLisaaineita": "Yoghurt, innehåller tillsatsämnen",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.RahkaMaustamaton": "Kvark, naturell",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.RahkaMaustettu": "Kvark, smaksatt",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.Vanukkaat": "Puddingar",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.MaitoJaHerapohjJuomat": "Mjölk- och vasslebaserade drycker",
  "tuotantotiedot.tuoretuotteidenvalmistus.field.MuutTuoretuotteet": "Övriga färskprodukter",
  "tuotantotiedot.juustojamuut.jauheetjapiimät.title": "Pulver",
  "tuotantotiedot.juustojamuut.field.KypsytetytJuustot": "Mognade ostar",
  "tuotantotiedot.juustojamuut.field.Raejuusto": "Grynost",
  "tuotantotiedot.juustojamuut.field.Tuorejuustot": "Färskostar",
  "tuotantotiedot.juustojamuut.field.Sulatejuustot": "Smältostar",
  "tuotantotiedot.juustojamuut.field.Leipajuusto": "Brödost",
  "tuotantotiedot.juustojamuut.field.Kutunjuusto": "Ostar",
  "tuotantotiedot.juustojamuut.field.VoiRasvapit80ProsJaYli": "Smör, mjölkfetthalt  80 % och över",
  "tuotantotiedot.juustojamuut.field.VoiRasvapitAlle80Pros": "Smör, mjölkfetthalt under  80 %",
  "tuotantotiedot.juustojamuut.field.Rasvaseokset": "Fettblandningar",
  "tuotantotiedot.juustojamuut.field.RasvaseoksetJostaMaitorasvaa": "...därav mjölkfett",
  "tuotantotiedot.juustojamuut.field.VoiOljy": "Smörolja",
  "tuotantotiedot.juustojamuut.field.VoiOljySuoraanKermasta": "Smörolja direkt av grädde",
  "tuotantotiedot.juustojamuut.field.RasvainenMaitojauhe": "Helmjölkspulver",
  "tuotantotiedot.juustojamuut.field.RasvatonMaitojauhe": "Skummjölkspulver",
  "tuotantotiedot.juustojamuut.field.KirnupiimaJaKirnumaitojauheet": "Kärnmjölks- och sött kärnmjölkspulver",
  "tuotantotiedot.juustojamuut.field.Herajauheet": "Vasslepulver",
  "tuotantotiedot.juustojamuut.field.MuutHeraMaitoJaSeosjauheet": "Övriga vassle-, mjölk och blandpulver",
  "tuotantotiedot.juustojamuut.field.Laktoosi": "Laktos",
  "tuotantotiedot.juustojamuut.field.KaseiiniJaKaseinaatit": "Kasein och kaseinater",
  "tuotantotiedot.juustojamuut.varastot.title": "Lager av ostar och smör (lager månadens sista dag)",
  "tuotantotiedot.juustojamuut.field.VarastointiKypsytetytJuustot": "Mognade ostar",
  "tuotantotiedot.juustojamuut.field.VarastointiVoi": "Smör",
  "tuotantotiedot.maitoraakaaineenvalmistus.title": "Tillverkningsmängden av mjölkråvara",
  "tuotantotiedot.maitoraakaaineenvalmistus.ohjeteksti": "Ange mängderna vid mejeriet framställd, såld och/eller använd mjölk \r\noch grädde för tillverkning av glass och mängd mjölkråvara för annan livsmedelsindustri än mejeriindustrin (bl.a. för chokladtillverkning).",
  "tuotantotiedot.maitoraakaaineenvalmistus.field.RaakaaineRasvatonMaito": "Fettfri mjölk",
  "tuotantotiedot.maitoraakaaineenvalmistus.field.RaakaaineMaito": "Mjölk",
  "tuotantotiedot.maitoraakaaineenvalmistus.field.RaakaaineKerma": "Grädde",
  "tuotantotiedot.juustomyynti.title": "Inhemsk försäljning av ostar (försäljning direkt för konsumtion)",
  "tuotantotiedot.juustomyynti.field.KypsytetytJuustot": "Mognade ostar och smältost",
  "tuotantotiedot.juustomyynti.field.Tuorejuustot": "Färskostar",
  "tuotantotiedot.juustomyynti.field.Leipäjuusto": "Brödost",
  "jälkitilit.title": "Efteravgifter",
  "jälkitilit.description": "Anmäla ditt företags årliga efteravgifter",
  "jälkitilit.jälkitili.title": "Efteravgift",
  "jälkitilit.jälkitili.ohjeteksti": "Anmäla betald efteravgift av mjölk som mottagits av producentrerna i föregående året",
  "jälkitilit.jälkitili.field.JälkitiliMaitomäärä": "Mjölkmängden, som efteravgift berör",
  "jälkitilit.jälkitili.field.JälkitiliTuottajilleMaksettu": "Betald efteravgift utan moms, snt/litra?",
  "jälkitilit.jälkitili.field.JälkitiliTuottajilleMaksettuYhteensä": "Betald efteravgift sammanlags, utan moms, euroa?",
  "jälkitilit.jälkitili.field.JälkitiliMaitomääräYhteensä": "Totala mjölkmängden, som mejeriet har mottagit under året",
  "jälkitilit.muutvuosittaisetlisät.title": "Övriga årliga tillägg",
  "jälkitilit.muutvuosittaisetlisät.ohjeteksti": "Anmäla alla andra eventuella tillägg/betalningar till producenterna för föregående året vilka betalas en eller några gånger per år, och som inte ingår i de uppgifter som ni uppger månatligen ",
  "jälkitilit.muutvuosittaisetlisät.lisät1": "Betald tillägg 1",
  "jälkitilit.muutvuosittaisetlisät.lisät2": "Betald tillägg 2",
  "jälkitilit.muutvuosittaisetlisät.field.MuutVuosittaisetLisätTeksti": "Övrig betald tillägg, vilket? ",
  "jälkitilit.muutvuosittaisetlisät.field.MuutVuosittaisetLisätMaitomäärä": "Mjölkmängden, som övriga tillägg berör",
  "jälkitilit.muutvuosittaisetlisät.field.MuutVuosittaisetLisätTuottajilleVuosittain": "Övriga årligen betalda tillägg åt producenterna, utan moms c/l",
  "jälkitilit.muutvuosittaisetlisät.field.MuutVuosittaisetLisätTuottajilleYhteensä": "Övriga årligen betalda tillägg åt producenterna, utan moms, euro",
  "valmis.kiitos": "Tack för ditt svar",
  "valmis.p": "",
  "valmis.uusi": "Välj ett nytt formulär",
  "footer.saavutettavuus": "Tillgänglighetsutlåtande",
  "footer.maitotilastot": "Mjölk- och mjölkproduktstatistik",
  "footer.tuottajahinnat": "Lantbruksprodukternas producentpriser",
  "luomumaitotuotteidenvalmistus.title": "Tillverking av ekologiska mejeriprodukter",
  "luomumaitotuotteidenvalmistus.description": "Anmäla ditt företags tillverkningsmängden av ekologiska mejeriprodukter",
  "luomumaitotuotteidenvalmistus.field.EiLuomumaitotuotteidenValmistusta": "Företaget har inte tillverkat ekologiska mejeriprodukter",
  "luomumaitotuotteidenvalmistus.field.LuomumaitotuotteidenValmistusMaidot": "Mjölk",
  "luomumaitotuotteidenvalmistus.field.LuomumaitotuotteidenValmistusKermat": "Grädde",
  "luomumaitotuotteidenvalmistus.field.LuomumaitotuotteidenValmistusPiimat": "Surmjölk",
  "luomumaitotuotteidenvalmistus.field.LuomumaitotuotteidenValmistusJogurtit": "Yoghurt",
  "luomumaitotuotteidenvalmistus.field.LuomumaitotuotteidenValmistusViilit": "Filer",
  "luomumaitotuotteidenvalmistus.field.LuomumaitotuotteidenValmistusVoi": "Smör",
  "luomumaitotuotteidenvalmistus.field.LuomumaitotuotteidenValmistusJuusto": "Ostar",
  "luomumaitotuotteidenvalmistus.field.LuomumaitotuotteidenValmistusMuut": "Andra produkter",
  "juustojenvalmistus.title": "Tillverkning av ost",
  "juustojenvalmistus.description": "Anmäla ditt företags tillverkningsmängden av ostar",
  "juustojenvalmistus.field.pehmeaJuusto": "Mjukost",
  "juustojenvalmistus.field.PuolipehmeaJuusto": "Halvmjuk ost",
  "juustojenvalmistus.field.puolikovaJuusto": "Halvhård ost",
  "juustojenvalmistus.field.kovaJuusto": "Hårdost",
  "juustojenvalmistus.field.erittainKovaJuusto": "Extra hård ost",
  "juustojenvalmistus.field.tuorejuusto": "Färskost"
}
