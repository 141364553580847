import { yupResolver } from "@hookform/resolvers/yup";
import {
  Fade,
  FormActions,
  FormInstructions,
  NextButton,
  PrevButton,
  TextField,
} from "@lukefi-private/tikal-storybook";
import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { useData, useQuery } from "../common";
import { EuroField, LitraField, SentitPerLitraField } from "../common/Fields";
import { nullableNumber } from "../common/FormUtils";

type MaidonVarastointitiedotForm = {
  MuutVuosittaisetLisät1Teksti: string;
  MuutVuosittaisetLisät1Maitomäärä: number;
  MuutVuosittaisetLisät1TuottajilleVuosittain: number;
  MuutVuosittaisetLisät1TuottajilleYhteensä: number;
  MuutVuosittaisetLisät2Teksti: string;
  MuutVuosittaisetLisät2Maitomäärä: number;
  MuutVuosittaisetLisät2TuottajilleVuosittain: number;
  MuutVuosittaisetLisät2TuottajilleYhteensä: number;
};

export default function MaitotuotteidenVarastointitiedot() {
  const query = useQuery();
  const history = useHistory();
  const { t } = useTranslation();
  const methods = useForm<MaidonVarastointitiedotForm>({
    shouldFocusError: true,
    reValidateMode: "onBlur",
    resolver: yupResolver(
      yup.object().shape({
        MuutVuosittaisetLisät1Teksti: yup.string(),
        MuutVuosittaisetLisät1Maitomäärä: nullableNumber,
        MuutVuosittaisetLisät1TuottajilleVuosittain: nullableNumber,
        MuutVuosittaisetLisät1TuottajilleYhteensä: nullableNumber,
        MuutVuosittaisetLisät2Teksti: yup.string(),
        MuutVuosittaisetLisät2Maitomäärä: nullableNumber,
        MuutVuosittaisetLisät2TuottajilleVuosittain: nullableNumber,
        MuutVuosittaisetLisät2TuottajilleYhteensä: nullableNumber,
      })
    ),
  });

  const { update, loading } = useData(
    query.get("id"),
    methods.reset,
    methods.setError,
    methods.getValues
  );

  const onSubmit = async () => {
    try {
      // Push data to backend
      await update.execute();

      // Navigate to next page
      history.push(
        `/jälkitilit/lomake/lisatiedot-ja-vahvistus?id=${query.get("id")}`
      );
    } catch (e) {
      console.error(e);
    }
  };

  /** Submit data on component unmount */
  useEffect(() => {
    return () => {
      update.execute();
    };
  }, []);

  const handlePreviousClick = () =>
    history.push(`/jälkitilit/lomake/jälkitili?id=${query.get("id")}`);

  return (
    <Fade>
      <FormProvider {...methods}>
        <Box>
          <Typography variant="h2">
            2. {t("jälkitilit.muutvuosittaisetlisät.title")}
          </Typography>
          <Box p={2} pt={0}>
            <FormInstructions>
              <Typography variant="body1">
                {t("jälkitilit.muutvuosittaisetlisät.ohjeteksti")}
              </Typography>
            </FormInstructions>
            <Box mt={2}>
              <Typography variant="h3">
                {t("jälkitilit.muutvuosittaisetlisät.lisät1")}
              </Typography>
              <Grid container spacing={2} sx={{ paddingBottom: "16px" }}>
                <Grid item md={6} xs={12}>
                  <TextField
                    name="MuutVuosittaisetLisät1Teksti"
                    fullWidth
                    label={t(
                      "jälkitilit.muutvuosittaisetlisät.field.MuutVuosittaisetLisätTeksti"
                    )}
                  ></TextField>
                </Grid>
              </Grid>
              <LitraField
                name="MuutVuosittaisetLisät1Maitomäärä"
                label={t(
                  "jälkitilit.muutvuosittaisetlisät.field.MuutVuosittaisetLisätMaitomäärä"
                )}
              />
              <SentitPerLitraField
                name="MuutVuosittaisetLisät1TuottajilleVuosittain"
                label={t(
                  "jälkitilit.muutvuosittaisetlisät.field.MuutVuosittaisetLisätTuottajilleVuosittain"
                )}
              />
              <EuroField
                name="MuutVuosittaisetLisät1TuottajilleYhteensä"
                label={t(
                  "jälkitilit.muutvuosittaisetlisät.field.MuutVuosittaisetLisätTuottajilleYhteensä"
                )}
              />
            </Box>
            <Box mt={2}>
              <Typography variant="h3">
                {t("jälkitilit.muutvuosittaisetlisät.lisät2")}
              </Typography>
              <Grid container spacing={2} sx={{ paddingBottom: "16px" }}>
                <Grid item md={6} xs={12}>
                  <TextField
                    name="MuutVuosittaisetLisät2Teksti"
                    fullWidth
                    label={t(
                      "jälkitilit.muutvuosittaisetlisät.field.MuutVuosittaisetLisätTeksti"
                    )}
                  ></TextField>
                </Grid>
              </Grid>
              <LitraField
                name="MuutVuosittaisetLisät2Maitomäärä"
                label={t(
                  "jälkitilit.muutvuosittaisetlisät.field.MuutVuosittaisetLisätMaitomäärä"
                )}
              />
              <SentitPerLitraField
                name="MuutVuosittaisetLisät2TuottajilleVuosittain"
                label={t(
                  "jälkitilit.muutvuosittaisetlisät.field.MuutVuosittaisetLisätTuottajilleVuosittain"
                )}
              />
              <EuroField
                name="MuutVuosittaisetLisät2TuottajilleYhteensä"
                label={t(
                  "jälkitilit.muutvuosittaisetlisät.field.MuutVuosittaisetLisätTuottajilleYhteensä"
                )}
              />
            </Box>

            <FormActions>
              <PrevButton onClick={handlePreviousClick}></PrevButton>
              <NextButton
                disabled={loading}
                onClick={methods.handleSubmit(onSubmit)}
              ></NextButton>
            </FormActions>
          </Box>
        </Box>
      </FormProvider>
    </Fade>
  );
}
