import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import useTokenState from "./useTokenState";

const GuardedRoute = (props: RouteProps) => {
  const auth = useTokenState();

  // Bypass guard in development env.
  if (!auth && process.env.NODE_ENV !== "development")
    return <Redirect to="/" />;

  return <Route {...props} />;
};

export default GuardedRoute;
