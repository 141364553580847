import { Fade, ShrinkingTitle } from "@lukefi-private/tikal-storybook";
import { Divider, Grid, List, ListItem, ListItemText } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Link,
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { Footer, Header } from "../common";
import FormContent from "../common/FormContent";
import PreviousValueProvider from "../common/PreviousValueContext";
import usePeriod from "../common/usePeriod";
import usePeriodTitle from "../common/usePeriodTitle";
import useQuery from "../common/useQuery";
import useTokenMetadata from "../common/useTokenMetadata";
import Valmis from "../common/Valmis";
import JuustoJaMuutMaitotuotteet from "./JuustoJaMuutMaitotuotteet";
import Lisätiedot from "./Lisätiedot";
import LuomumaidonVastaanottotiedot from "./LuomumaidonVastaanotto";
import MaidonVastaanottotiedot from "./MaidonVastaanottotiedot";
import MaitoRaakaaineenValmistustiedot from "./MaitoRaakaaineenValmistustiedot";
import TuoretuotteidenValmistus from "./TuoretuotteidenValmistus";

const useStyles = makeStyles((theme) => ({
  nav: {
    color: "black",
    paddingRight: theme.spacing(2),
  },
  navLink: {
    color: "inherit",
    textDecoration: "none",
  },
  divider: {
    marginTop: "24px",
    marginRight: "-1px",
    marginBottom: "24px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
}));

export default function MaidontuotantoRouter() {
  const { t } = useTranslation();
  const location = useLocation();
  const query = useQuery();
  const { path } = useRouteMatch();
  const classes = useStyles();
  const [fade, setFade] = useState(false);
  const { period } = usePeriod(query.get("id") as string);
  const trTitle = usePeriodTitle();

  useEffect(() => {
    setTimeout(() => setFade(true), 500);
  }, []);

  return (
    <>
      <Header />
      <ShrinkingTitle
        variant="h5"
        title={`${t("tuotantotiedot.title")} - ${trTitle(period?.name) || ""}`}
      ></ShrinkingTitle>
      <FormContent>
        <Fade in={fade}>
          <Grid container spacing={2}>
            <Grid item lg={2} md={3} xs={12}>
              <List
                className={classes.nav}
                component="nav"
                sx={{ paddingRight: "16px" }}
              >
                <Link
                  className={classes.navLink}
                  to={`${path}/maidon-vastaanotto?id=${query.get("id")}`}
                >
                  <ListItem
                    dense
                    button
                    selected={
                      location.pathname.endsWith("maidon-vastaanotto") &&
                      !location.pathname.includes("luomu")
                    }
                  >
                    <ListItemText
                      primary={"1. " + t("tuotantotiedot.vastaanotto.title")}
                    />
                  </ListItem>
                </Link>

                <div>
                  {useTokenMetadata("LUOMUMAIDON_VASTAANOTTO") && (
                    <Link
                      className={classes.navLink}
                      to={`${path}/luomumaidon-vastaanotto?id=${query.get(
                        "id"
                      )}`}
                    >
                      <ListItem
                        dense
                        button
                        selected={location.pathname.endsWith(
                          "luomumaidon-vastaanotto"
                        )}
                      >
                        <ListItemText
                          primary={
                            "1.1. " +
                            t("tuotantotiedot.luomumaidontuottajahinta.title")
                          }
                        />
                      </ListItem>
                    </Link>
                  )}
                </div>

                <Link
                  className={classes.navLink}
                  to={`${path}/tuoretuotteiden-valmistus?id=${query.get("id")}`}
                >
                  <ListItem
                    dense
                    button
                    selected={location.pathname.includes(
                      "tuoretuotteiden-valmistus"
                    )}
                  >
                    <ListItemText
                      primary={
                        "2. " +
                        t("tuotantotiedot.tuoretuotteidenvalmistus.title")
                      }
                    />
                  </ListItem>
                </Link>

                <Link
                  className={classes.navLink}
                  to={`${path}/juusto-ja-muut-maitotuotteet-valmistus-varastot?id=${query.get(
                    "id"
                  )}`}
                >
                  <ListItem
                    dense
                    button
                    selected={location.pathname.includes(
                      "juusto-ja-muut-maitotuotteet-valmistus-varastot"
                    )}
                  >
                    <ListItemText
                      primary={"3. " + t("tuotantotiedot.juustojamuut.title")}
                    />
                  </ListItem>
                </Link>

                <Link
                  className={classes.navLink}
                  to={`${path}/maitoraakaaineen-valmistus?id=${query.get(
                    "id"
                  )}`}
                >
                  <ListItem
                    dense
                    button
                    selected={location.pathname.includes(
                      "maitoraakaaineen-valmistus"
                    )}
                  >
                    <ListItemText
                      primary={
                        "4. " +
                        t("tuotantotiedot.maitoraakaaineenvalmistus.title")
                      }
                    />
                  </ListItem>
                </Link>
                <Link
                  className={classes.navLink}
                  to={`${path}/lisatiedot-ja-vahvistus?id=${query.get("id")}`}
                >
                  <ListItem
                    dense
                    button
                    selected={location.pathname.includes(
                      "lisatiedot-ja-vahvistus"
                    )}
                  >
                    <ListItemText
                      primary={"5. " + t("common.title.lisätiedot")}
                    />
                  </ListItem>
                </Link>
              </List>
            </Grid>

            <Divider
              orientation="vertical"
              flexItem
              className={classes.divider}
            />
            <PreviousValueProvider mode="month">
              <Grid item lg={10} md={9} xs={12}>
                <Switch>
                  <Route
                    exact
                    path={`${path}/maidon-vastaanotto`}
                    component={MaidonVastaanottotiedot}
                  />

                  <Route
                    exact
                    path={`${path}/tuoretuotteiden-valmistus`}
                    component={TuoretuotteidenValmistus}
                  />

                  <Route
                    exact
                    path={`${path}/luomumaidon-vastaanotto`}
                    component={LuomumaidonVastaanottotiedot}
                  />

                  <Route
                    exact
                    path={`${path}/juusto-ja-muut-maitotuotteet-valmistus-varastot`}
                    component={JuustoJaMuutMaitotuotteet}
                  />
                  <Route
                    exact
                    path={`${path}/maitoraakaaineen-valmistus`}
                    component={MaitoRaakaaineenValmistustiedot}
                  />
                  <Route
                    exact
                    path={`${path}/lisatiedot-ja-vahvistus`}
                    component={Lisätiedot}
                  />
                  <Route exact path={`${path}/valmis`} component={Valmis} />
                  <Redirect
                    exact
                    from={`${path}/`}
                    to={`${path}/maidon-vastaanotto?id=${query.get("id")}`}
                  />
                </Switch>
              </Grid>
            </PreviousValueProvider>
          </Grid>
        </Fade>
      </FormContent>
      <Footer />
    </>
  );
}
