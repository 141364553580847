import { yupResolver } from "@hookform/resolvers/yup";
import {
  Fade,
  FormActions,
  FormInstructions,
  NextButton,
} from "@lukefi-private/tikal-storybook";
import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { useData, useQuery } from "../common";
import { EuroField, LitraField, SentitPerLitraField } from "../common/Fields";
import { nullableNumber } from "../common/FormUtils";

type JälkitiliForm = {
  JälkitiliMaitomäärä: number;
  JälkitiliTuottajilleMaksettu: number;
  JälkitiliTuottajilleMaksettuYhteensä: number;
  JälkitiliMaitomääräYhteensä: number;
};

export default function MaitotuotteidenVarastointitiedot() {
  const query = useQuery();
  const history = useHistory();

  const methods = useForm<JälkitiliForm>({
    shouldFocusError: true,
    reValidateMode: "onBlur",
    resolver: yupResolver(
      yup.object().shape({
        JälkitiliMaitomäärä: nullableNumber,
        JälkitiliTuottajilleMaksettu: nullableNumber,
        JälkitiliTuottajilleMaksettuYhteensä: nullableNumber,
        JälkitiliMaitomääräYhteensä: nullableNumber,
      })
    ),
  });

  const { update, loading } = useData(
    query.get("id"),
    methods.reset,
    methods.setError,
    methods.getValues
  );

  const { t } = useTranslation();

  const onSubmit = async () => {
    try {
      // Push data to backend
      await update.execute();

      // Navigate to next page
      history.push(
        `/jälkitilit/lomake/muut-vuosittaiset-lisät?id=${query.get("id")}`
      );
    } catch (e) {
      console.error(e);
    }
  };

  /** Submit data on component unmount */
  useEffect(() => {
    return () => {
      update.execute();
    };
  }, []);

  return (
    <Fade>
      <FormProvider {...methods}>
        <Box>
          <Typography variant="h2">
            1. {t("jälkitilit.jälkitili.title")}
          </Typography>
          <Box p={2} pt={0}>
            <FormInstructions>
              <Typography variant="body1">
                {t("jälkitilit.jälkitili.ohjeteksti")}
              </Typography>
            </FormInstructions>

            <LitraField
              name="JälkitiliMaitomäärä"
              label={t("jälkitilit.jälkitili.field.JälkitiliMaitomäärä")}
              showPreviousLabel
            />
            <SentitPerLitraField
              name="JälkitiliTuottajilleMaksettu"
              label={t(
                "jälkitilit.jälkitili.field.JälkitiliTuottajilleMaksettu"
              )}
            />
            <EuroField
              name="JälkitiliTuottajilleMaksettuYhteensä"
              label={t(
                "jälkitilit.jälkitili.field.JälkitiliTuottajilleMaksettuYhteensä"
              )}
            />
            <LitraField
              name="JälkitiliMaitomääräYhteensä"
              label={t(
                "jälkitilit.jälkitili.field.JälkitiliMaitomääräYhteensä"
              )}
            />
            <FormActions>
              <span />
              <NextButton
                disabled={loading}
                onClick={methods.handleSubmit(onSubmit)}
              ></NextButton>
            </FormActions>
          </Box>
        </Box>
      </FormProvider>
    </Fade>
  );
}
