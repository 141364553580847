import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles(() => ({
  box: {
    minHeight: "calc(100vh - 335px)",
  },
}));

export default function FormContent({
  children,
}: React.PropsWithChildren<any>) {
  const classes = useStyles();

  return (
    <Box className={classes.box} pr={8} pl={8} pt={4} pb={2}>
      {children}
    </Box>
  );
}
