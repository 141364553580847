import { Fade } from "@lukefi-private/tikal-storybook";
import { ArrowRight } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import useLogout from "./useLogout";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(2),
  },
  box: {
    backgroundColor: "#e4f6cd",
  },
}));

export default function Valmis() {
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();

  const handleLogout = useLogout();

  return (
    <Fade>
      <Box>
        <Typography variant="h2">{t("valmis.kiitos")}</Typography>
      </Box>
      <Box pt={8}>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={() => history.push(`/aloitus`)}
          endIcon={<ArrowRight />}
        >
          {t("valmis.uusi")}
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={handleLogout}
          endIcon={<ArrowRight />}
        >
          {t("common.logout")}
        </Button>
      </Box>
    </Fade>
  );
}
