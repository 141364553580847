import { Action, Actions, Title } from "@lukefi-private/tikal-storybook";
import { Box, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useTranslation } from "react-i18next";
import juustoImage from "../../assets/juusto.jpg";
import maidontuotantoImage from "../../assets/lehma_lahikuvassa.jpg";
import luomumaitoImage from "../../assets/maitolasi.jpg";
import jalkitiliImage from "../../assets/raha2.jpg";
import { Footer, Header } from "../common";
import useInquiries from "../common/useInquiries";
import useTokenUserInfo from "../common/useTokenUserInfo";

const useStyles = makeStyles(() => ({
  box: {
    minHeight: "calc(100vh - 335px)",
  },
}));

export default function Aloitus() {
  const classes = useStyles();
  const { t } = useTranslation();
  const user = useTokenUserInfo();
  const { inquiries } = useInquiries();

  return (
    <>
      <Header />
      <Title>
        <Typography variant="h1">
          {t("common.hello")}, {user?.gn}!
        </Typography>
        <Box mt={2}>
          <Typography variant="body1">{t("common.valitse.lomake")}</Typography>
        </Box>
      </Title>
      <Box className={classes.box}>
        <Actions>
          {inquiries.findIndex((i) => i.key === "maidontuotanto") > -1 && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Action
                title={t("tuotantotiedot.title")}
                subtitle={t("tuotantotiedot.description")}
                image={maidontuotantoImage}
                href="/maidontuotanto"
              />
            </Grid>
          )}
          {inquiries.findIndex((i) => i.key === "jälkitilit") > -1 && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Action
                title={t("jälkitilit.title")}
                subtitle={t("jälkitilit.description")}
                image={jalkitiliImage}
                href="/jälkitilit"
              />
            </Grid>
          )}
          {inquiries.findIndex(
            (i) => i.key === "luomumaitotuotteiden-valmistus"
          ) > -1 && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Action
                title={t("luomumaitotuotteidenvalmistus.title")}
                subtitle={t("luomumaitotuotteidenvalmistus.description")}
                image={luomumaitoImage}
                href="/luomumaitotuotteiden-valmistus"
              />
            </Grid>
          )}
          {inquiries.findIndex((i) => i.key === "juustojen-valmistus") > -1 && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Action
                title={t("juustojenvalmistus.title")}
                subtitle={t("juustojenvalmistus.description")}
                image={juustoImage}
                href="/juustojen-valmistus"
              />
            </Grid>
          )}
        </Actions>
      </Box>

      <Footer />
    </>
  );
}
