import jwt from "jsonwebtoken";
import useTokenState from "./useTokenState";

const devMeta = [
  {
    key: "LUOMUMAIDON_VASTAANOTTO",
    value: true,
  },
];

// Take the desired piece of the metadata table's data, determined by function parameter value
export default function useTokenMetadata(key: string): {} | null {
  if (process.env.NODE_ENV === "development") {

    // This loop checks if the desired key exists, return a value from the object that contained the key
    for (let  i = 0; devMeta.length > i; i++) {
      if (key === devMeta[i].key) {
        return devMeta[i].value;
      }
    }
  
  }
  
  const token = useTokenState();

  if (token) {
    try {
      const payload = jwt.decode(token) as any;
      let metadata = payload.m;
      
      // If metadata of token does not exist, return falsy value as null
      if (!payload.m) {
        return null;
      }

      // This loop checks if the desired key exists, return a value from the object that contained the key
      for (let  i = 0; metadata.length > i; i++) {
        if (key === metadata[i].key) {
          return metadata[i].value;
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  return null;
}
