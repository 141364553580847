import { NumberField, NumberFormatter } from "@lukefi-private/tikal-storybook";
import { Grid, InputAdornment } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useContext, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { NumberFormatValues } from "react-number-format";
import PreviousValue from "./PreviousValue";
import { PreviousValueContext } from "./PreviousValueContext";

type FieldProps = {
  name: string;
  label: string;
  showPreviousLabel?: boolean;
  indent?: boolean;
  compareToPrevious?: boolean;
};

type BaseFieldProps = {
  name: string;
  label: string;
  InputProps: any;
  showPreviousLabel?: boolean;
  indent?: boolean;
  compareToPrevious?: boolean;
};

export function KgField(props: FieldProps) {
  return (
    <Field
      InputProps={{
        endAdornment: <InputAdornment position="end">kg</InputAdornment>,
        inputComponent: NumberFormatter,
        inputProps: {
          maxLength: 10,
          allowNegative: false,
        },
      }}
      {...props}
    />
  );
}

export function KplField(props: FieldProps) {
  const { t } = useTranslation();
  return (
    <Field
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">{t("common.kpl")}</InputAdornment>
        ),
        inputComponent: NumberFormatter,
        inputProps: { maxLength: 5, allowNegative: false },
      }}
      {...props}
    />
  );
}

export function LitraField(props: FieldProps) {
  return (
    <Field
      InputProps={{
        endAdornment: <InputAdornment position="end">l</InputAdornment>,
        inputComponent: NumberFormatter,
        inputProps: { maxLength: 11, allowNegative: false },
      }}
      {...props}
    />
  );
}

export function EuroField(props: FieldProps) {
  return (
    <Field
      InputProps={{
        endAdornment: <InputAdornment position="end">€</InputAdornment>,
        inputComponent: NumberFormatter,
        inputProps: {
          maxLength: 12,
          decimalScale: 2,
          allowNegative: false,
          isAllowed: ({ floatValue }: NumberFormatValues) => {
            if (floatValue === undefined || isNaN(floatValue)) return true;

            return floatValue < 99999999;
          },
        },
      }}
      {...props}
    />
  );
}

export function SenttiField(props: FieldProps) {
  const { t } = useTranslation();

  return (
    <Field
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">{t("common.snt")}</InputAdornment>
        ),
        inputComponent: NumberFormatter,
        inputProps: {
          maxLength: 5,
          decimalScale: 2,
          allowNegative: false,
          isAllowed: ({ floatValue }: NumberFormatValues) => {
            if (floatValue === undefined || isNaN(floatValue)) return true;

            return floatValue < 100;
          },
        },
      }}
      {...props}
    />
  );
}

export function SentitPerLitraField(props: FieldProps) {
  const { t } = useTranslation();

  return (
    <Field
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {t("common.sntperlitra")}
          </InputAdornment>
        ),
        inputComponent: NumberFormatter,
        inputProps: { maxLength: 5, decimalScale: 2, allowNegative: false },
      }}
      {...props}
    />
  );
}

export function ProsenttiField(props: FieldProps) {
  return (
    <Field
      InputProps={{
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
        inputComponent: NumberFormatter,
        inputProps: {
          maxLength: 5,
          decimalScale: 2,
          allowNegative: false,
          isAllowed: ({ floatValue }: NumberFormatValues) => {
            if (floatValue === undefined || isNaN(floatValue)) return true;

            return floatValue >= 0 && floatValue < 100;
          },
        },
      }}
      {...props}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  indent: {
    paddingLeft: theme.spacing(6) + " !important",
  },
}));

export default function Field({
  name,
  label,
  showPreviousLabel,
  InputProps,
  indent,
  compareToPrevious,
}: BaseFieldProps) {
  const [helpText, setHelptext] = useState<string | undefined>();
  const { t } = useTranslation();
  const previousValues = useContext(PreviousValueContext);
  const { watch } = useFormContext();
  const value = watch(name as string);
  const classes = useStyles();

  const toggleComparison = () => {
    if (compareToPrevious && value) {
      if (
        (previousValues && value > previousValues.values[name] * 1.2) ||
        value < previousValues.values[name] * 0.8
      )
        setHelptext(t("tuotantotiedot.PoikkeavaArvo"));
      else setHelptext(undefined);
    } else setHelptext(undefined);
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        paddingBottom: "16px",
      }}
    >
      <Grid item md={6} xs={12} className={indent ? classes.indent : ""}>
        <NumberField
          value={value}
          InputProps={InputProps}
          name={name}
          fullWidth
          onBlur={toggleComparison}
          label={label}
          helpText={helpText}
        ></NumberField>
      </Grid>
      {previousValues.mode && (
        <Grid item md={6} xs={12}>
          <PreviousValue
            showLabel={showPreviousLabel}
            id={name}
            InputProps={InputProps}
            value={previousValues.values[name]}
            label={label}
          />
        </Grid>
      )}
    </Grid>
  );
}
