export const SET_TOKEN = 'SET_TOKEN';
export const DELETE_TOKEN = 'DELETE_TOKEN';

interface SetTokenAction {
  type: typeof SET_TOKEN;
  payload: string;
}

interface DeleteTokenAction {
  type: typeof DELETE_TOKEN;
}

export interface AuthState {
  token?: string;
}

export type AuthActions = SetTokenAction | DeleteTokenAction;
